import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { FormControl, InputGroup } from 'react-bootstrap';
import SearchIcon from "../../../assets/img/icons/request-system/search.svg";

const AdvanceTableSearchBox = ({
  globalFilter,
  setGlobalFilter,
  placeholder = 'Digite sua pesquisa aqui'
}) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || "");
  }, 500);

  return (
    <InputGroup className="position-relative index-search-box">
      <FormControl
        size="sm"
        id="search"
        type="search"
        value={value || ''}
        placeholder={placeholder}
        className="shadow-none search-box-index"
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value);
        }}
      />
      <Button
        className='search-button-index'
        onClick={() => {
          setGlobalFilter(value);
        }}
      >
        <img 
          src={SearchIcon}
          alt='Recarregar'
          className='button-icon'
        />
      </Button>
    </InputGroup>
  );
};

export default AdvanceTableSearchBox;
