import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EditIcon from "assets/img/icons/request-system/edit.svg";

export default function EditButton({ route }) {
  const navigate = useNavigate();

  return (
    <Button 
      title='Editar'
      className='edit-button-index'
      onClick={() => { navigate(route) }}
    >
      <img 
        src={EditIcon}
        alt='Recarregar'
        className='button-icon'
      />
    </Button>
  )
}