import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { Form } from 'react-bootstrap';
import SelectMenuModal from './selectMenuModal';
import baseController from 'api/base.controller';
import React, { useState, useEffect } from 'react';
import ConfigureProductsModal from './configureProductsModal';
import AdvanceTable from '../../../common/advance-table/AdvanceTable';
import FieldValidationMessage from '../../sistema/fieldValidationMessage';
import AdvanceTableWrapperEvento from '../../../common/advance-table/AdvanceTableWrapperEvento';
import AdvanceTablePaginationEvento from '../../../common/advance-table/AdvanceTablePaginationEvento';

const adjustDataToCombo = (data) => {
  if(data)
    return data.map((loja) => {
      return {
        label: loja.nome,
        value: loja.id,
        nome: loja.nome,
        imagem: loja.imagem,
        cardapios: loja.cardapios
      }
    });
};

const adjustComboToData = (data) => {
  return data.map((loja) => {
    return {
      id: loja.value,
      nome: loja.nome,
      imagem: loja.imagem,
      cardapios: loja.cardapios
    }
  });
}

export default function LojaForm({ data, setData, validated }) {
  const { auth } = useAuth();
  const [comboValues, setComboValues] = useState([]);
  const [lojas, setLojas] = useState(data.lojas ? data.lojas : []);
  const [value, setValue] = useState(adjustDataToCombo(data.lojas));

  const columns = [
    {
      accessor: 'imagem',
      Header: '',
      Cell: (rowData) => {
        const { imagem } = rowData.row.original;
        
        return (
          <img 
            src={imagem}
            loading='lazy'
            style={{ width: '40px', maxHeight: '50px' }} 
          />
        )
      },
      cellProps:{
        style: {
          width: '50px'
        }
      }
    },
    {
      accessor: 'nome',
      Header: 'Nome'
    },
    {
      Header: 'Loja Configurada?',
      cellProps: {
        style: {
          width: '100px'
        }
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        
        return (
          data.eventoLojaCardapios.some(elc => elc.lojaId === id) &&
          data.eventoLojaPontoVendas.some(elpv => elpv.lojaId === id) &&
          data.eventoLojaFormaPagamentos.some(elfp => elfp.lojaId === id) &&
          data.eventoLojaInformacoesAdicionais.some(elai => elai.lojaId === id) ?
            <p>Sim</p> : 
            <p>Não</p>
        )
      }
    },
    {
      Actions: true,
      accessor: 'id',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
  
        return (
          <SelectMenuModal
            id={id}
            data={data}
            setData={setData}
          />
        )
      }
    },
    {
      Actions: true,
      accessor: 'lojaId',
      Cell: (rowData) => {
        const { id } = rowData.row.original;
  
        return (
          <ConfigureProductsModal
            id={id}
            data={data}
            setData={setData}
          />
        )
      }
    }
  ]

  useEffect(() => {
    getComboData();
  }, []);

  useEffect(() => {
    if(value) setLojas(adjustComboToData(value));
  }, [value]);

  useEffect(() => {
    const newData = { ...data };
    newData.lojas = lojas;

    setData(newData);
  }, [lojas]);

  const getComboData = async () => {
    baseController.get('Loja/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then(res => setComboValues(adjustDataToCombo(res.data)));
  };

  return(
    <>
      <Form.Group
        className='mb-3'
        controlId='formGroupLojas'
      >
        <Form.Label>Lojas</Form.Label>
        <Select
          isMulti
          name="lojas"
          value={value}
          options={comboValues}
          closeMenuOnSelect={false}
          classNamePrefix="react-select"
          placeholder="Selecione as lojas"
          onChange={value => setValue(value)}
          className={data.lojas.length === 0 && validated ? 'form-control is-invalid select-lojas-event-form' : 'select-lojas-event-form'}
        />
        <Form.Control.Feedback type="invalid">
          <FieldValidationMessage fieldName="Lojas" />
        </Form.Control.Feedback>
      </Form.Group>
      <AdvanceTableWrapperEvento
        pagination
        perPage={6}
        data={lojas}
        columns={columns}
      >
        <AdvanceTable
          table
          rowClassName="align-middle white-space-nowrap"
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          tableProps={{
            striped: true,
            bordered: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTablePaginationEvento table />
        </div>
      </AdvanceTableWrapperEvento>
    </>
  )
}