import { Card } from 'react-bootstrap';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import React, { useRef } from 'react';
import { getColor } from 'helpers/utils';
import generateRandomHexColor from 'components/pages/sistema/randomColorGenerator';
import FormatCurrency from 'components/pages/sistema/formatCurrency';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (data) => ({
  tooltip: {
    trigger: 'item',
    backgroundColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    formatter: '<strong>{b}</strong><br/> {c} ({d}%)'
  },
  legend: { show: false },
  series: [
    {
      name: 'Venda por Evento',
      type: 'pie',
      radius: ['65%', '90%'],
      selectedMode: 'single',
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      labelLine: { show: false },
      data: data
    },
  ]
});

const VendaCaixa = ({ data }) => {
  data = data.map((item) => {
    return {
      value: item.total,
      name: item.caixaNome,
      itemStyle: {
        color: generateRandomHexColor()
      }
    }
  });

  const VendasCaixaList = ({ data }) => {
    return (
      <Row className="g-3 font-sans-serif">
        <Col sm={12}>
          <div className="d-flex align-items-center mb-4">
            <h6 className="mb-0 fw-bold">Caixas</h6>
          </div>
          <div className="rounded-3 p-3 vendas-justify">
            
            <ul className="list-unstyled mb-0">
              {data.map((item, index) => (
                <li
                  key={item.name}
                  className={`d-flex justify-content-evenly fs--2 fw-medium pt-1 ${
                    index !== data.length - 1 && 'mb-3'
                  }`}
                >
                  <FontAwesomeIcon
                    icon="circle"
                    className='me-2'
                    color={item.itemStyle?.color}
                  />
                  <p className="lh-sm mb-0 text-700 vendas-text">
                    {item.name}
                    <span className="text-900 ps-2"><FormatCurrency number={item.value} /></span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };
  
  const VendasCaixaChart = ({ data }) => {
    const chartRef = useRef(null);
  
    return (
      <div className="position-relative py-2">
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(data)}
          style={{ minHeight: '18.75rem' }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center">
          <div className="rounded-circle d-flex flex-center marketing-exp-circle">
            <h4 className="mb-0 text-900">
              <FormatCurrency number={(data.reduce((soma, item) => soma + item.value, 0))} />
            </h4>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Card className="h-100 dashboard-card-shadow">
      <Card.Body className="d-flex flex-column justify-content-between">
        <h4 className="mt-1">Vendas por Caixa</h4>
        <VendasCaixaChart data={data} />
        <VendasCaixaList data={data} />   
      </Card.Body>

      <Card.Footer className="bg-light py-2 d-flex">
        <div className="ms-auto">
        </div>
      </Card.Footer>
    </Card>
  );
};

export default VendaCaixa;