const icmsSituacaoTributariaData = [
  { value: "0", label: "0 - Tributada integralmente" },
  { value: "2", label: "2 - Tributação monofásica própria sobre combustíveis" },
  { value: "10", label: "10 - Tributada e com cobrança do ICMS por substituição tributária" },
  { value: "10_partilha", label: "10_partilha - Tributada e com cobrança do ICMS por substituição tributária (partilha)" },
  { value: "15", label: "15 - Tributação monofásica própria com responsabilidade pela retenção sobre combustíveis" },
  { value: "20", label: "20 - Tributada com redução de base de cálculo" },
  { value: "30", label: "30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária" },
  { value: "40", label: "40 - Isenta" },
  { value: "41", label: "41 - Não tributada" },
  { value: "41_st", label: "41_st - Não tributada com ICMS ST devido para a UF de destino (operações interestaduais)" },
  { value: "50", label: "50 - Suspensão" },
  { value: "51", label: "51 - Diferimento (preenchimento opcional das informações do ICMS diferido)" },
  { value: "53", label: "53 - Tributação monofásica sobre combustíveis com recolhimento diferido" },
  { value: "60", label: "60 - Cobrado anteriormente por substituição tributária" },
  { value: "60_st", label: "60_st - Cobrado anteriormente por substituição tributária (partilha)" },
  { value: "61", label: "61 - Tributação monofásica sobre combustíveis cobrada anteriormente" },
  { value: "70", label: "70 - Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária" },
  { value: "90", label: "90 - Outras (regime Normal)" },
  { value: "90_partilha", label: "90_partilha - Outras (regime Normal, com partilha do ICMS)" },
  { value: "101", label: "101 - Tributada pelo Simples Nacional com permissão de crédito" },
  { value: "102", label: "102 - Tributada pelo Simples Nacional sem permissão de crédito" },
  { value: "103", label: "103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta" },
  { value: "201", label: "201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária" },
  { value: "202", label: "202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária" },
  { value: "203", label: "203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária" },
  { value: "300", label: "300 - Imune" },
  { value: "400", label: "400 - Não tributada pelo Simples Nacional" },
  { value: "500", label: "500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação" },
  { value: "900", label: "900 - Outras (regime Simples Nacional)" }
];

export default icmsSituacaoTributariaData;