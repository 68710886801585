import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { dispositivoRoles } from '../sistema/allowedRoles';
import FullScreenLoading from '../sistema/fullScreenLoading';
import FieldValidationMessage from '../sistema/fieldValidationMessage';

export default function DispositivoEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [valuePessoa, setValuePessoa] = useState(null);
  const [comboValuesPessoa, setComboValuesPessoa] = useState([]);
  const [data, setData] = useState({
    nome: "",
    uuid: "",
    tipo: "",
    status: "",
    pessoaId: ""
  });

  const getData = async () => {
    setLoading(true);

    if (id) {
      baseController.get(`Dispositivo/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => {
          setData(res.data);
          setValuePessoa(adjustDataSingleToComboPessoa(res.data.pessoa));
        });
    }
    
    baseController.get('Pessoa/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then((res) => setComboValuesPessoa(adjustDataToComboPessoa(res.data)))
      .finally(() => setLoading(false));
  };

  const adjustDataSingleToComboPessoa = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };

  const adjustDataToComboPessoa = (data) => {
    return data.map((pessoa) => {
      return {
        value: pessoa.id,
        nome: pessoa.nome,
        label: pessoa.nome
      }
    })
  };

  useEffect(() => {
    const pessoaId = valuePessoa?.value;
    const newData = {...data};
    
    newData['pessoaId'] = pessoaId;
    newData['pessoa'] = null;
    
    setData(newData);
  }, [valuePessoa]);

  useEffect(() => {
    const isAuthorized = dispositivoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role)

    setAuthorized(isAuthorized);

    if(isAuthorized) getData();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return null;
    }

    id ? 
      baseController.put('Dispositivo', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) : 
      baseController.post('Dispositivo', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }
  
  const handleData = (event) => {
    const newData = {...data};

    switch(event.target.type) {
      case 'checkbox':
          newData[event.target.name] = event.target.checked;
          break;
      default:
          newData[event.target.name] = event.target.value;
          break;
    }

    setData(newData);
  };

  if(loading) return (<FullScreenLoading />)

  if(!authorized) return (<Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar Dispositivo' : 'Incluir Dispositivo'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='dispositivo-form'
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.nome}
            placeholder="Nome da máquina"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Nome"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupMaquina">
          <Form.Label>Id da Máquina</Form.Label>
          <Form.Control
            required
            name="uuid"
            type="text"
            minLength={1}
            maxLength={36}
            value={data.uuid}
            placeholder="UUID da máquina"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={1}
              maxLength={36}
              fieldName={"UUID da máquina"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupTipo">
          <Form.Label>Tipo da Máquina</Form.Label>
          <Form.Control
            required
            name="tipo"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.tipo}
            placeholder="Tipo da máquina"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Tipo da máquina"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupStatus">
          <Form.Label>Status da Máquina</Form.Label>
          <Form.Check 
            id='status'
            name='status'
            type='switch'
            checked={data.status}
            onChange={handleData}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPessoa">
          <Form.Label>Pessoa</Form.Label>
          <div style={{width:"100%"}}>
            <Select
              required
              name='pessoaId'
              value={valuePessoa}
              menuPlacement='auto'
              menuPosition='fixed'
              closeMenuOnSelect={true}
              options={comboValuesPessoa}
              classNamePrefix='react-select'
              placeholder='Selecione a pessoa'
              onChange={value => setValuePessoa(value)}
              className={validated && valuePessoa === undefined ? 'form-control is-invalid' : ''}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage fieldName={"Pessoa"} />
            </Form.Control.Feedback>
          </div>
        </Form.Group>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button 
          variant="danger" 
          type="submit" 
          onClick={() => {
            navigate('/dispositivo');
          }}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="dispositivo-form"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}