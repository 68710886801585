import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import baseController from 'api/base.controller';
import React, { useEffect, useState } from 'react';
import HomeEvento from '../dashboards/graficos/homeEventos';
import FullScreenLoading from '../sistema/fullScreenLoading';

export default function Home() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [eventosAtivosData, setEventosAtivosData] = useState([]);
  const [ultimosEventosData, setUltimosEventosData] = useState([]);
  const [lojasClienteMensal, setLojasClienteMensal] = useState([]);
  const [eventosAtivosByLojaData, setEventosAtivosByLojaData] = useState([]);
  const [ultimosEventosByLojaData, setUltimosEventosByLojaData] = useState([]);
  const isLojaEvento = ['LE-A', 'LE-V'].includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);
  const isClienteMensal = ['CM-A', 'CM-V'].includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    const headers = {
      Authorization: auth.token ?? JSON.parse(localStorage.getItem('auth')).token
    };

    if(isClienteMensal) {
      const resLojasClienteMensal = await baseController.get('Home/LojasClienteMensal', {headers});
      const lojasClienteToUse = [];

      resLojasClienteMensal.data?.forEach(loja => {
        lojasClienteToUse.push({
          id: loja.eventoId,
          nome: loja.lojaNome,
          lojaId: loja.lojaId
        })
      });

      setLojasClienteMensal(lojasClienteToUse);
    } 
    else if(isLojaEvento) {
      const resEventosAtivosByLoja = await baseController.get('Home/EventosAtivosByLoja', {headers});
      const eventosAtivosToUse = [];

      resEventosAtivosByLoja.data?.forEach(loja => {
        loja.eventos?.forEach(evento => {
          eventosAtivosToUse.push({
            id: evento.id,
            nome: evento.nome,
            lojaId: loja.lojaId,
            lojaNome: loja.lojaNome,
            dataInicio: evento.dataInicio,
            dataTermino: evento.dataTermino
          })
        });
      });

      setEventosAtivosByLojaData(eventosAtivosToUse);

      const resUltimosEventosByLoja = await baseController.get('Home/UltimosEventosByLoja', {headers});
      const ultimosEventosToUse = [];

      resUltimosEventosByLoja.data?.forEach(loja => {
        loja.eventos?.forEach(evento => {
          ultimosEventosToUse.push({
            id: evento.id,
            nome: evento.nome,
            lojaId: loja.lojaId,
            lojaNome: loja.lojaNome,
            dataInicio: evento.dataInicio,
            dataTermino: evento.dataTermino
          })
        });
      });

      setUltimosEventosByLojaData(ultimosEventosToUse);
    } 
    else {
      const eventosAtivos = await baseController.get('Home/EventosAtivos', { headers });
      setEventosAtivosData(eventosAtivos.data);

      const ultimosEventos = await baseController.get('Home/UltimosEventos', { headers });
      setUltimosEventosData(ultimosEventos.data);
    }

    setIsLoading(false);
  };

  if(isLoading) return (<FullScreenLoading />);

  if(isClienteMensal) {
    if(lojasClienteMensal.length === 1) {
      const value = lojasClienteMensal[0];

      navigate(`/evento/dashboard/${value.id}/${value.lojaId}`);
    } else {
      return (
        <Card className='dashboard-card home-card-container'>
          <div className='home-eventos-container'>
            <h3 className='mt-1 home-title'>Lojas em Operação</h3>
            <Row className='g-1 mb-1 row-eventos'>
              <Col md={12} className='col-eventos'>
                <HomeEvento 
                  isClienteMensal
                  data={lojasClienteMensal}
                />
              </Col>
            </Row>
          </div>
        </Card>
      )
    }
  }

  if(isLojaEvento) {
    return (
      <Card className='dashboard-card home-card-container'>
        <div className='home-eventos-container'>
          <h3 className='mt-1 home-title'>Eventos em Operação</h3>
          <Row className='g-1 mb-1 row-eventos'>
            <Col md={12} className='col-eventos'>
              <HomeEvento 
                isLojaEvento
                data={eventosAtivosByLojaData}
              />
            </Col>
          </Row>
        </div>
        <div className='home-eventos-container'>
          <h3 className='mt-1 home-title'>Eventos Realizados</h3>
          <Row className='g-1 mb-1 row-eventos'>
            <Col md={12} className='col-eventos'>
              <HomeEvento 
                isLojaEvento
                data={ultimosEventosByLojaData}
              />
            </Col>
          </Row>
        </div>
      </Card>
    )
  }

  return (
    <Card className='dashboard-card home-card-container'>
      <div className='home-eventos-container'>
        <h3 className='mt-1 home-title'>Eventos em Operação</h3>
        <Row className='g-1 mb-1 row-eventos'>
          <Col md={12} className='col-eventos'>
            <HomeEvento 
              data={eventosAtivosData}
              isClienteMensal={isClienteMensal}
            />
          </Col>
        </Row>
      </div>
      <div className='home-eventos-container'>
        <h3 className='mt-1 home-title'>Eventos Realizados</h3>
        <Row className='g-1 mb-1 row-eventos'>
          <Col md={12} className='col-eventos'>
            <HomeEvento data={ultimosEventosData}/>
          </Col>
        </Row>
      </div>
    </Card>
  )
}