import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PasswordButton({data, setData}) {
  let characters = '';
  let passwordLength = 0;

  const setUpperCase = isUpperCase => {
    if (isUpperCase) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    return '';
  }

  const setLowerCase = isLowerCase => {
    if (isLowerCase) {
        characters += 'abcdefghijklmnopqrstuvwxyz';
    }
    return '';
  }

  const setSymbols = isSymbol => {
    if (isSymbol) {
        characters += '!@#$%^&*()<>,.?/[]{}-=_+|/';
    }
    return '';
  }

  const setNumber = isNumeric => {
    if (isNumeric) {
        characters += '0123456789';
    }
    return '';
  }

  const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const passwordCharacters = () => {
    let password = '';
    if (characters.length) {
        for (let i = 0; i < passwordLength; i++) {
            password += characters[getRandomInteger(0, characters.length - 1)];
        }
        characters = '';
        passwordLength = 0;
        return password;
    }
  }

  const setPasswordLength = length => {
    passwordLength = length;
    return passwordLength;
  }

  const generatePassword = () => {
    const passwordProps = {uppercase: true, lowercase: true, symbols: true, numbers: true}
    const { uppercase, lowercase, symbols, numbers } = passwordProps;
    setPasswordLength(20);
    setUpperCase(uppercase);
    setLowerCase(lowercase);
    setSymbols(symbols);
    setNumber(numbers);
    const password = passwordCharacters();
    
    const newData = {...data};
    newData['senha'] = password;
    setData(newData);
  }

  return (
    <Button
      title='Gerar senha'
      className='generate-password-button'
      onClick={generatePassword}
    >
      <FontAwesomeIcon
        icon={['fa', 'key']}
        className='table-insert-button'
      />
    </Button>
  )
}