import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { useDropzone } from "react-dropzone";
import baseController from "api/base.controller";
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from "react";
import { lojaRoles } from "../sistema/allowedRoles";
import UploadField from '../sistema/formUploadField';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import FieldValidationMessage from '../sistema/fieldValidationMessage';

export default function LojaEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState((<></>));
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [valuePessoa, setValuePessoa] = useState(null);
  const [comboValuesPessoa, setComboValuesPessoa] = useState([]);
  const [data, setData] = useState({
    id: "",
    nome: "",
    imagem: "",
    ativo: true,
    fileName: null,
    formFile: null,
    clienteMensal: false,
    utilizaEstoque: false,
    idPessoaResponsavel: null,
    eventos: [],
    cardapios: [],
  });

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
      accept: 'image/*'
  });

  const adjustDataToComboPessoa = (data) => {
    return data.map((pessoa) => {
      return {
        value: pessoa.id,
        nome: pessoa.nome,
        label: pessoa.nome
      }
    })
  };

  const adjustDataSingleToComboPessoa = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };

  const getData = async () => {
    setLoading(true);

    baseController.get(`Loja/${id}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then(async (res) => {
        setData(res.data);
        
        const editAdjustedToComboPessoa = adjustDataSingleToComboPessoa(res.data.pessoaResponsavel);
        setValuePessoa(editAdjustedToComboPessoa);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const isAuthorized = lojaRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized && id) {
      getData();
    }

    baseController.get('Pessoa/GetByTipoPessoa/J', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    }).then((result) => {
      const adjustedToComboPessoa = adjustDataToComboPessoa(result.data);
      setComboValuesPessoa(adjustedToComboPessoa);
    });
  }, []);

  useEffect(() => {
    const newData = { ...data };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function() {
        const filteredBase64 = reader.result.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        newData['fileName'] = file.name;
        newData['formFile'] = filteredBase64;

        setData(newData);

        setFiles((
          <li key={file.path}>
            <img src={reader.result}  height='100' />
          </li>
        ))
      };

      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  useEffect(() => {
    const pessoaId = valuePessoa?.value;
    const newData = {...data};
    
    newData['idPessoaResponsavel'] = pessoaId;
    newData['pessoaResponsavel'] = null;

    setData(newData);
  }, [valuePessoa]);

  const handleSubmit = async(event) => {
    setLoading(true);
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return;
    }

    id ?
      baseController.put('Loja', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) :
      baseController.post('Loja', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }

  const handleData = (event, type) => {
    const newData = {...data};
    
    switch(type) {
      case 'switch':
          newData[event.target.name] = event.target.checked;
          break;
      default:
          newData[event.target.name] = event.target.value;
          break;
    }

    setData(newData);
  };

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar loja' : 'Incluir loja'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='loja-form'
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group 
          className="mb-3" 
          controlId="formGroupName"
        >
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.nome}
            placeholder="Nome da loja"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Nome"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPerson">
          <Form.Label>Cliente Responsável</Form.Label>
          <Select
            name='idPessoaResponsavel'
            value={valuePessoa}
            closeMenuOnSelect={true}
            options={comboValuesPessoa}
            classNamePrefix='react-select'
            placeholder='Selecione a pessoa'
            onChange={(valuePessoa) => { setValuePessoa(valuePessoa) }}
            className={validated && valuePessoa === null ? 'form-control is-invalid' : ''}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage fieldName={"Pessoa"} />
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Col>
            <Form.Group controlId="formGroupClienteMensal">
              <Form.Label>Cliente Mensal?</Form.Label>
              <Form.Check 
                type='switch'
                id='clienteMensal'
                name='clienteMensal'
                checked={data.clienteMensal}
                onChange={(e) => handleData(e, "switch")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupAtivo">
              <Form.Label>Ativo?</Form.Label>
              <Form.Check 
                type='switch'
                id='ativo'
                name='ativo'
                checked={data.ativo}
                onChange={(e) => handleData(e, "switch")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formGroupUtilizaEstoque">
              <Form.Label>Utiliza Estoque?</Form.Label>
              <Form.Check 
                type='switch'
                id='utilizaEstoque'
                name='utilizaEstoque'
                checked={data.UtilizaEstoque}
                onChange={(e) => handleData(e, "switch")}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group 
          className="mb-3" 
          controlId="formGroupImagem"
        >
          <Form.Label>Imagem</Form.Label>
          <UploadField
            files={files}
            required={!id && !data.formFile}
            multiple={false}
            fieldName="Imagem"
            imgUrl={data.imagem}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            acceptedFiles={acceptedFiles}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer className="edit-buttons-wrapper">
        <Button 
          variant="danger"
          onClick={() => navigate(-1) }
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="loja-form"
          className="edit-save-button"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}