import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { useDropzone } from 'react-dropzone';
import React, { useEffect, useState } from "react";
import UploadField from '../../sistema/formUploadField';
import EditIcon from "assets/img/icons/request-system/edit.svg";
import DeleteIcon from 'assets/img/icons/request-system/delete.svg';
import InsertIcon from 'assets/img/icons/request-system/insert.svg';
import FieldValidationMessage from '../../sistema/fieldValidationMessage';
import { Row, Col, Form, Modal, Button, Accordion, InputGroup } from "react-bootstrap";
import FullScreenLoading from "components/pages/sistema/fullScreenLoading";

import icmsOrigemData from 'components/pages/produtos/Fiscal/icmsOrigemData';
import pisSituacaoTributariaData from 'components/pages/produtos/Fiscal/pisSituacaoTributaria';
import icmsSituacaoTributariaData from 'components/pages/produtos/Fiscal/icmsSituacaoTributaria';
import cofinsSituacaoTributariaData from 'components/pages/produtos/Fiscal/cofinsSituacaoTributaria';

export default function ConfigureProductsModal({ id, data, setData }) {
  const [loaded, setLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [temporaryData, setTemporaryData] = useState(data);
  const [productModalShow, setProductModalShow] = useState(false);
  const [categoryModalShow, setCategoryModalShow] = useState(false);
  const [productModalCategoryId, setProductModalCategoryId] = useState(null);

  const [icmsOrigem, setIcmsOrigem] = useState(null);
  const [pisSituacaoTributaria, setPisSituacaoTributaria] = useState(null);
  const [icmsSituacaoTributaria, setIcmsSituacaoTributaria] = useState(null);
  const [cofinsSituacaoTributaria, setCofinsSituacaoTributaria] = useState(null);

  const defaultProduct = {
    id: "",
    nome: "",
    imagem: "",
    descricao: "",
    formFile: null,
    fileName: null,
    categoriaId: "",
    precoPorKg: false,
    produtoFiscal: {
      id: null,
      cfop: "",
      codigoNcm: "",
      codigoCest: null,
      icmsOrigem: "",
      icmsSituacaoTributaria: "",
      icmsPorcentagem: null,
      pisSituacaoTributaria: "",
      pisPorcentagem: null,
      cofinsSituacaoTributaria: "",
      cofinsPorcentagem: null
    }
  }
  const [productToAdd, setProductToAdd] = useState(defaultProduct);

  const [files, setFiles] = useState((<></>));
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*'
  });


  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if(modalShow) {
      const newData = { ...data };

      const cardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id) ?? null;

      if(!cardapio) {
        newData.eventoLojaCardapios.push({
          id: uuid(),
          lojaId: id,
          categorias: []
        });
      }
    }
  }, [modalShow]);

  useEffect(() => {
    const newData = {...productToAdd};

    if (newData.produtoFiscal)
      newData.produtoFiscal.icmsOrigem = icmsOrigem?.value;

    setProductToAdd(newData);
  }, [icmsOrigem]);

  useEffect(() => {
    const newData = {...productToAdd};

    if (newData.produtoFiscal)
      newData.produtoFiscal.pisSituacaoTributaria = pisSituacaoTributaria?.value;

    setProductToAdd(newData);
  }, [pisSituacaoTributaria]);

  useEffect(() => {
    const newData = {...productToAdd};

    if (newData.produtoFiscal)
      newData.produtoFiscal.icmsSituacaoTributaria = icmsSituacaoTributaria?.value;

    setProductToAdd(newData);
  }, [icmsSituacaoTributaria]);

  useEffect(() => {
    const newData = {...productToAdd};

    if (newData.produtoFiscal)
      newData.produtoFiscal.cofinsSituacaoTributaria = cofinsSituacaoTributaria?.value;

    setProductToAdd(newData);
  }, [cofinsSituacaoTributaria]);

  const handleData = (event, produto) => {
    const value =  parseFloat(event.target.value);

    if(value <= 0) {
      event.target.setCustomValidity(" ");
      event.target.reportValidity();
    } else {
      event.target.setCustomValidity("");
      produto.preco = value;
    }
  };

  const handleConfirm = () => {
    setData(() => {
      setModalShow(false);
      
      return temporaryData;
    })
  };

  const handleAddCategory = () => {
    const newData = { ...temporaryData };
    const cardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id) ?? null;

    if (cardapio) {
      cardapio.categorias.push({
        id: uuid(),
        cardapioId: cardapio.id,
        exibicao: categoryName,
        produtos: []
      });
    }

    setTemporaryData(newData);

    setCategoryName("");
    setCategoryModalShow(false);
  };

  const handleRemoveCategory = (category) => {
    const newData = { ...temporaryData };
    const cardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id) ?? null;
    const index = cardapio.categorias.indexOf(category);

    if(index !== -1)
      cardapio.categorias.splice(index, 1);

    setTemporaryData(newData);

    setModalShow(true);
  };

  const handleRemoveProduct = (product) => {
    const newData = { ...temporaryData };
    const cardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id) ?? null;
    const categoria = cardapio?.categorias.find(ct => ct.id === product.categoriaId) ?? null
    const index = categoria?.produtos.indexOf(product) ?? -1;

    if (index !== -1)
      categoria.produtos?.splice(index, 1);

    setTemporaryData(newData);
  };

  const handleAddProduct = () => {
    const newData = { ...temporaryData }

    const cardapio = newData.eventoLojaCardapios.find(elc => elc.lojaId === id) ?? null;
    const categoria = cardapio?.categorias.find(ct => ct.id === productModalCategoryId) ?? null;
    let produto = categoria?.produtos.find(p => p.id === productToAdd.id) ?? null;
    const produtoIndex = categoria.produtos.indexOf(produto);

    if (categoria && !produto) {
      productToAdd.id = uuid();
      productToAdd.categoriaId = productModalCategoryId;

      categoria.produtos.push(productToAdd);
    } else if (produto)
      categoria.produtos[produtoIndex] = productToAdd;

    setFiles((<></>));
    setProductModalCategoryId(null);
    setProductToAdd(defaultProduct);
    setProductModalShow(false);
    setTemporaryData(newData);
  };

  const handleProductData = (event, type) => {
    const produto = { ...productToAdd };
    const splittedName = event.target.name.split('.');
    const splitLength = splittedName.length ?? 0;

    if(splitLength === 2 && !produto.produtoFiscal)
      produto.produtoFiscal = {};

    switch(type) {
      case 'switch': {
        produto[event.target.name] = event.target.checked;
        break;
      }
      case 'number': {
        const value =  parseFloat(event.target.value);

        if(splitLength === 2)
          produto[splittedName[0]][splittedName[1]] = value;
        else
          produto[event.target.name] = value;

        if(value <= 0) {
          event.target.setCustomValidity(" ");
          event.target.reportValidity();
        } else event.target.setCustomValidity("");

        break;
      }
      default: {
        if(splitLength === 2)
          produto[splittedName[0]][splittedName[1]] = event.target.value;
        else
          produto[event.target.name] = event.target.value;
        
        break;
      }
    }
    
    setProductToAdd(produto);
  };

  useEffect(() => {
    const produto = { ...productToAdd };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function() {
        const base64 = reader.result
        const filteredBase64 = base64.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        produto.imagem = base64;
        produto.fileName = file.name;
        produto.formFile = filteredBase64;

        setProductToAdd(produto);

        setFiles((
          <li key={file.path}>
            <img src={reader.result}  height='100' />
          </li>));
      };
      
      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  return (
    <>
      <Button
        title="Configurar Cardápio"
        onClick={() => {
          setModalShow(true);
        }}
      >
        Configurar Cardápio
      </Button>
      <Modal
        centered
        fullscreen
        show={modalShow}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => { setCategoryName(""); setProductToAdd({}); setModalShow(false); }}
      >
        <Modal.Header>
          <Modal.Title
            className="select-menu-modal-title"
            id="contained-modal-title-vcenter"
          >
            Configuração do Cardápio
          </Modal.Title>
        </Modal.Header>
        <Modal.Body 
          as={Form}
          id="config-menu-form"
          onSubmit={handleConfirm}
          className="cardapio-select-modal-body config-store-modal-form"
        >
          {loaded ?
            <Accordion style={{width: '100%'}}>
            {temporaryData.eventoLojaCardapios.find(elc => elc.lojaId === id)?.categorias.map((categoria) => {
                return (
                  <Accordion.Item 
                    key={categoria.id}
                    eventKey={categoria.id}
                  >
                    <Accordion.Header>
                      { categoria.exibicao }
                    </Accordion.Header>
                    <Accordion.Body className='edit-cardapio-accordion-body'>
                      <div className='category-accordion-buttons-container'>
                        <Button
                          variant="success"
                          title='Adicionar Produto'
                          className="accordion-button-in-modal"
                          onClick={() => {
                            setProductModalCategoryId(categoria.id);
                            setProductModalShow(true);
                          }}
                        >
                          <img 
                            src={InsertIcon}
                            alt='Adicionar Produto'
                            className='button-icon-in-modal'
                          />
                        </Button>
                        <Button
                          variant="danger"
                          title='Remover Categoria'
                          className="accordion-button-in-modal"
                          onClick={() => handleRemoveCategory(categoria)}
                        >
                          <img 
                            alt='Remover'
                            src={DeleteIcon}
                            className='button-icon-in-modal'
                          />
                        </Button>
                      </div>
                      {categoria.produtos.map((produto) => {
                        return (
                          <div key={produto.id} style={{position: 'relative', width: '95%', display: 'flex', flexDirection: 'row',  justifyContent: 'flex-start', borderBottom: '1px solid #252525',}}>
                            <img style={{maxWidth: 100, maxHeight: 100, padding: 5}} src={produto.imagem} />
                            <div style={{display: 'flex', flexDirection: 'column', padding: 5}}>
                              <p style={{color: '#252525', fontWeight: '800'}}>{produto.nome}</p>
                              <p style={{color: '#252525', fontWeight: '500'}}>{produto.descricao}</p>
                            </div>
                            <Form.Group
                              className="mb-3"
                              controlId="formGroupPreco"
                              style={{position: 'absolute', right: '5rem'}}
                            >
                              <Form.Label>Preço</Form.Label>
                              <InputGroup>
                                <InputGroup.Text>R$</InputGroup.Text>
                                <Form.Control
                                  required
                                  name="preco"
                                  type="number"
                                  placeholder={produto.preco}
                                  onChange={(e) => handleData(e, produto)}
                                />
                                <Form.Control.Feedback type="invalid">
                                <FieldValidationMessage
                                  fieldName={"Preço"}
                                  overridenMessage={"O campo 'Preço' deve ser maior do que 0."}
                                />
                              </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                            <Button
                              variant="primary"
                              title="Editar"
                              onClick={() => {
                                setProductToAdd(produto);
                                setIcmsOrigem(icmsOrigemData.filter(value => value.value === (produto.produtoFiscal?.icmsOrigem))[0]);
                                setPisSituacaoTributaria(pisSituacaoTributariaData.filter(value => value.value === (produto.produtoFiscal?.pisSituacaoTributaria))[0]);
                                setIcmsSituacaoTributaria(icmsSituacaoTributariaData.filter(value => value.value === (produto.produtoFiscal?.icmsSituacaoTributaria))[0]);
                                setCofinsSituacaoTributaria(cofinsSituacaoTributariaData.filter(value => value.value === (produto.produtoFiscal?.cofinsSituacaoTributaria))[0]);
                                setProductModalCategoryId(categoria.id);
                                setProductModalShow(true);
                              }}
                              className="accordion-button-in-modal-product first-accordion-button border-dark"
                            >
                              <img 
                                alt='Editar'
                                src={EditIcon}
                                className='button-icon-in-modal'
                              />
                            </Button>
                            <Button
                              variant="secundary"
                              title="Remover Produto"
                              onClick={() => handleRemoveProduct(produto)}
                              className="accordion-button-in-modal-product border-dark"
                            >
                              <img 
                                alt='Remover'
                                src={DeleteIcon}
                                className='button-icon-in-modal'
                              />
                            </Button>
                          </div>
                        )
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
            :
            <FullScreenLoading />
          }
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => setCategoryModalShow(true)}
            style={{
              left: 5,
              position: 'absolute'
            }}
          >
            Adicionar Categoria
          </Button>
          <Button
            title="Finalizar"
            onClick={handleConfirm}
          >
            Finalizar
          </Button>
        </Modal.Footer>

        <Modal
          centered
          size="sm"
          show={categoryModalShow}
          onHide={() => { setCategoryName(""); setCategoryModalShow(false) }}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            closeButton
          >
            Digite o nome da categoria.
          </Modal.Header>
          <Modal.Body>
            <Form.Group
              className="mb-3"
              controlId="formGroupCategoriaNome"
            >
              <Form.Label>Nome</Form.Label>
              <Form.Control
                required
                name="nome"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Nome"}
              />
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              style={{
                width: "100%"
              }}
              onClick={handleAddCategory}
            >
              Confirmar
            </Button>
          </Modal.Body>
        </Modal>

        { (productModalShow || categoryModalShow) &&
          <div className='full-screen-loading'>
          </div>
        }
        <Modal
          centered
          size="xl"
          show={productModalShow}
          onHide={() => { 
            setProductModalCategoryId(null);
            setProductToAdd(defaultProduct); 
            setProductModalShow(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header
            closeButton
          >
            Adicionar produto.
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
              <Form.Group
                className="mb-3"
                controlId="formGroupCategoriaNome"
              >
                <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    name="nome"
                    type="text"
                    minLength={2}
                    maxLength={100}
                    value={productToAdd.nome}
                    placeholder="Nome do produto"
                    onChange={(e) => handleProductData(e, 'text')}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FieldValidationMessage
                      minLength={2}
                      maxLength={100}
                      fieldName={"Nome"}
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group 
                  className="mb-3" 
                  controlId="formGroupDescricao"
                >
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    rows={4}
                    required
                    type="text"
                    as="textarea"
                    minLength={2}
                    maxLength={400}
                    name="descricao"
                    value={productToAdd.descricao}
                    placeholder="Descrição do produto"
                    onChange={(e) => handleProductData(e, 'text')}
                  />
                  <Form.Control.Feedback type="invalid">
                    <FieldValidationMessage
                      minLength={2}
                      maxLength={400}
                      fieldName={"Descrição"}
                    />
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="formGroupPreco"
                >
                  <Form.Label>Preço</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>R$</InputGroup.Text>
                    <Form.Control
                      required
                      name="preco"
                      type="number"
                      placeholder="0,00"
                      value={productToAdd.preco}
                      onChange={(e) => handleProductData(e, 'number')}
                    />
                    <Form.Control.Feedback type="invalid">
                    <FieldValidationMessage
                      fieldName={"Preço"}
                      overridenMessage={"O campo 'Preço' deve ser maior do que 0."}
                    />
                  </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group 
                  className="mb-3" 
                  controlId="formGroupImagem"
                >
                  <Form.Label>Imagem</Form.Label>
                  <UploadField
                    files={files}
                    multiple={false}
                    fieldName="Imagem"
                    getRootProps={getRootProps}
                    imgUrl={productToAdd.imagem}
                    getInputProps={getInputProps}
                    acceptedFiles={acceptedFiles}
                    required={!productToAdd.formFile}
                  />
                </Form.Group>
                <Form.Group 
                  className="mb-3" 
                  controlId="formGroupPrecoPorKg"
                >
                  <Form.Label>Preço por Kg?</Form.Label>
                  <Form.Check
                    type='switch'
                    id='precoPorKg'
                    name='precoPorKg'
                    checked={productToAdd.precoPorKg}
                    onChange={(e) => handleProductData(e, "switch")}
                  />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group 
                className="mb-3" 
                controlId="formGroupCfop"
              >
                <Form.Label>Código CFOP</Form.Label>
                <Form.Control
                  name="produtoFiscal.cfop"
                  type="text"
                  value={productToAdd.produtoFiscal?.cfop}
                  placeholder="Código CFOP"
                  onChange={(e) => handleProductData(e)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Código CFOP"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupNcm"
              >
                <Form.Label>Código NCM</Form.Label>
                <Form.Control
                  name="produtoFiscal.codigoNcm"
                  type="text"
                  value={productToAdd.produtoFiscal?.codigoNcm}
                  placeholder="Código NCM"
                  onChange={(e) => handleProductData(e)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Código NCM"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group 
                className="mb-3" 
                controlId="formGroupCest"
              >
                <Form.Label>Código CEST</Form.Label>
                <Form.Control
                  name="produtoFiscal.codigoCest"
                  type="text"
                  value={productToAdd.produtoFiscal?.codigoCest}
                  placeholder="Código CEST"
                  onChange={(e) => handleProductData(e)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Código CEST"}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupIcmsOrigem">
                <Form.Label>ICMS Origem</Form.Label>
                <Select
                  value={icmsOrigem}
                  closeMenuOnSelect={true}
                  name='produtoFiscal.icmsOrigem'
                  options={icmsOrigemData}
                  classNamePrefix='react-select'
                  placeholder='Selecione a Origem'
                  onChange={(value) => setIcmsOrigem(value)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"Pessoa"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupIcmsIcmsSituacaoTributaria">
                <Form.Label>ICMS Situação Tributária</Form.Label>
                <Select
                  value={icmsSituacaoTributaria}
                  closeMenuOnSelect={true}
                  name='produtoFiscal.icmsSituacaoTributaria'
                  options={icmsSituacaoTributariaData}
                  classNamePrefix='react-select'
                  placeholder='Selecione a situação tributária do ICMS'
                  onChange={(value) => setIcmsSituacaoTributaria(value)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"ICMS Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupIcmsPercentual"
              >
                <Form.Label>ICMS</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    name="produtoFiscal.icmsPorcentagem"
                    type="number"
                    value={productToAdd.produtoFiscal?.icmsPorcentagem}
                    placeholder="0,00"
                    onChange={(e) => handleProductData(e, 'number')}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"ICMS"}
                  />
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="formGroupPisSituacaoTributaria">
                <Form.Label>PIS Situação Tributária</Form.Label>
                <Select
                  value={pisSituacaoTributaria}
                  closeMenuOnSelect={true}
                  name='produtoFiscal.pisSituacaoTributaria'
                  options={pisSituacaoTributariaData}
                  classNamePrefix='react-select'
                  placeholder='Selecione a situação tributária do PIS'
                  onChange={(value) => setPisSituacaoTributaria(value)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"PIS Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupPisPercentual"
              >
                <Form.Label>PIS</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    name="produtoFiscal.pisPorcentagem"
                    type="number"
                    value={productToAdd.produtoFiscal?.pisPorcentagem}
                    placeholder="0,00"
                    onChange={(e) => handleProductData(e, 'number')}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"PIS"}
                  />
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="formGroupCofinsSituacaoTributaria">
                <Form.Label>Cofins Situação Tributária</Form.Label>
                <Select
                  value={cofinsSituacaoTributaria}
                  closeMenuOnSelect={true}
                  name='produtoFiscal.cofinsSituacaoTributaria'
                  options={cofinsSituacaoTributariaData}
                  classNamePrefix='react-select'
                  placeholder='Selecione a situação tributária do Cofins'
                  onChange={(value) => setCofinsSituacaoTributaria(value)}
                />
                <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage fieldName={"Cofins Situação Tributária"} />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formGroupCofinsPercentual"
              >
                <Form.Label>Cofins</Form.Label>
                <InputGroup>
                  <InputGroup.Text>%</InputGroup.Text>
                  <Form.Control
                    name="produtoFiscal.cofinsPorcentagem"
                    type="number"
                    value={productToAdd.produtoFiscal?.cofinsPorcentagem}
                    placeholder="0,00"
                    onChange={(e) => handleProductData(e, 'number')}
                  />
                  <Form.Control.Feedback type="invalid">
                  <FieldValidationMessage
                    fieldName={"Cofins"}
                  />
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              </Col>
            </Row>
            <Button
              style={{
                width: "100%"
              }}
              onClick={handleAddProduct}
            >
              Confirmar
            </Button>
          </Modal.Body>
        </Modal>
      </Modal>
    </>
  )
}