import React from "react";
import { Button } from "react-bootstrap";
import ReloadIcon from "assets/img/icons/request-system/reload.svg";

export default function ReloadButton({getData}) {
    return (
        <Button 
            title='Recarregar'
            onClick={() => getData()}
            className='reload-button-index'
        >
            <img 
                alt='Recarregar'
                src={ReloadIcon}
                className='button-icon'
            />
        </Button>
    );
}