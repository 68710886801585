import Lottie from 'lottie-react';
import React, { useEffect, useRef } from "react";
import loading from '../../../assets/img/animated-icons/loading.json';
import SlowLoading from './slowLoading';

export default function FullScreenLoading({ isSlow }) {
  const lottieRef = useRef;

  useEffect(() => {
    lottieRef.current.setSpeed(1.75);
  }, [lottieRef]);

  return (
    <div className='full-screen-loading'>
      <Lottie
          loop={true}
          lottieRef={lottieRef}
          animationData={loading}
          className='loading-animation'
        />
        { isSlow ? <SlowLoading /> : <></> }
    </div>
  )
}