import React from 'react';
import Flex from 'components/common/Flex';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import LoginForm from 'components/pages/login/LoginForm';
import squareLogo from 'assets/img/generic/logo-color-blackbg-square.svg';


const Login = () => {
  return (
    <AuthSplitLayout bgProps={{ image: squareLogo, position: '0 0' }}>
      <Flex alignItems="center" justifyContent="center">
        <h4>Login</h4>
      </Flex>
      <LoginForm layout="split" hasLabel />
    </AuthSplitLayout>
  );
};

export default Login;
