import React from 'react';
import Hero from 'components/pages/landing/Hero';
// import Partners from 'components/pages/landing/Partners';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import Processes from 'components/pages/landing/Processes';
import Cta from 'components/pages/landing/Cta';
import FooterStandard from 'components/pages/landing/FooterStandard';

export default function PaginaInicial() {

  return (
    <>
        <NavbarStandard />
        <Hero />
        {/* <Partners /> */}
        <Processes />
        <Cta />
        <FooterStandard />
    </>
  );
}