import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { funcaoRoles } from '../sistema/allowedRoles';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import FieldValidationMessage from '../sistema/fieldValidationMessage';

export default function FuncaoEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [data, setData] = useState({
    nome: "",
    tipoAcesso: ""
  });
  
  const getData = async () => {
    setLoading(true);

    baseController.get(`Funcao/${id}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
    .then((res) => setData(res.data))
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    const isAuthorized = funcaoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized && id) getData();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return;
    }
    
    id ? 
      baseController.put('Funcao', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) : 
      baseController.post('Funcao', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }
  
  const handleData = (event) => {
    const newData = {...data};

    newData[event.target.name] = event.target.value;

    setData(newData);
  }

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar Função' : 'Incluir Função'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id="funcao-form"
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.nome}
            placeholder="Nome da função"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={100}
                fieldName={"Nome"}
              />
          </Form.Control.Feedback>
          <Form.Label>Tipo de Acesso</Form.Label>
          <div style={{width:"100%"}}>
            <Form.Select
              required
              name='tipoAcesso'
              value={data.tipoAcesso}
              placeholder='Selecione o tipo de acesso'
              onChange={(e) =>{ setValidated(false); handleData(e) }}
            >
              <option value="RS">Administrador - Request System</option>
              <option value="T">Técnico - Request System</option>

              <option value="CM-A">Administrador - Cliente Mensal</option>
              <option value="CM-C">Cadastros - Cliente Mensal</option>
              <option value="CM-V">Visualização - Cliente Mensal</option>

              <option value="F">Financeiro - Cliente Eventos</option>
              <option value="G">Gerente - Cliente Eventos</option>
              <option value="A">Administrador - Cliente Eventos</option>

              <option value="LE-A">Administrador - Loja Eventos</option>
              <option value="LE-C">Cadastros - Loja Eventos</option>
              <option value="LE-V">Visualização - Loja Eventos</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage fieldName={"Tipo de Acesso"} />
            </Form.Control.Feedback>
          </div>
        </Form.Group>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button
          type="submit"
          variant="danger"
          onClick={() => {
            navigate('/funcao');
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="funcao-form"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}