import React from "react";

export default function DateColumn({date, showTime}) {
  const jsDate = new Date(date);
  const hour = jsDate.getHours() < 10 ? '0' + jsDate.getHours() : jsDate.getHours();
  const minutes = jsDate.getMinutes() < 10 ? '0' + jsDate.getMinutes() : jsDate.getMinutes();
  const dateString = jsDate.toLocaleDateString('pt-BR');
  return (
    <div>
      { showTime ? 
          dateString + ' ' + hour + ':' + minutes : 
          dateString 
      }
    </div>
  )
}