import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { Form } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import baseController from 'api/base.controller';
import AdvanceTable from '../../../common/advance-table/AdvanceTable';
import FieldValidationMessage from '../../sistema/fieldValidationMessage';
import AdvanceTableWrapperEvento from 'components/common/advance-table/AdvanceTableWrapperEvento';
import AdvanceTablePaginationEvento from 'components/common/advance-table/AdvanceTablePaginationEvento';

const columns = [
  {
    accessor: 'nome',
    Header: 'Nome'
  }
];

const adjustDataToCombo = (data) => {
  if (data)
    return data.map((pessoa) => {
      return {
        rg: pessoa.rg,
        cpf: pessoa.cpf,
        value: pessoa.id,
        nome: pessoa.nome,
        tipo: pessoa.tipo,
        label: pessoa.nome,
        email: pessoa.email,
        endereco: pessoa.endereco,
        telefone: pessoa.telefone,
        razaoSocial: pessoa.razaoSocial,
        nomeFantasia: pessoa.nomeFantasia
      }
    });
};

const adjustComboToData = (data) => {
  return data.map((pessoa) => {
    return {
      id: pessoa.value,
      rg: pessoa.rg,
      cpf: pessoa.cpf,
      nome: pessoa.nome,
      tipo: pessoa.tipo,
      email: pessoa.email,
      endereco: pessoa.endereco,
      telefone: pessoa.telefone,
      razaoSocial: pessoa.razaoSocial,
      nomeFantasia: pessoa.nomeFantasia
    }
  })
}

export default function ParticipantForm({ data, setData, validated }) {
  const { auth } = useAuth();
  const [comboValues, setComboValues] = useState([]);
  const [value, setValue] = useState(adjustDataToCombo(data.pessoas));
  const [pessoas, setPessoas] = useState(data.pessoas ? data.pessoas : []);

  useEffect(() => {
    getComboData();
  }, []);

  useEffect(() => {
    if(value) setPessoas(adjustComboToData(value));
  }, [value])

  useEffect(() => {
    const newData = { ...data };
    newData.pessoas = pessoas;
    
    setData(newData);
  }, [pessoas])

  const getComboData = async () => {
    baseController.get('Pessoa/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then((res) => setComboValues(adjustDataToCombo(res.data)));
  };

  return(
    <>
      <Form.Group 
        className="mb-3" 
        controlId="formGroupPessoas"
      >
        <Form.Label>Participantes</Form.Label>
        <Select
          isMulti
          value={value}
          name="pessoas"
          options={comboValues}
          closeMenuOnSelect={false}
          classNamePrefix="react-select"
          onChange={value => setValue(value)}
          placeholder='Selecione os participantes'
          className={data.pessoas.length === 0 && validated ? 'form-control is-invalid select-pessoas-event-form' : 'select-pessoas-event-form'}
        />
        <Form.Control.Feedback type="invalid">
          <FieldValidationMessage fieldName={"Participantes"} />
        </Form.Control.Feedback>
      </Form.Group>
      <AdvanceTableWrapperEvento
        sortable
        pagination
        perPage={6}
        data={pessoas}
        columns={columns}
      >
        <AdvanceTable
          table
          rowClassName="align-middle white-space-nowrap"
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          tableProps={{
            striped: true,
            bordered: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTablePaginationEvento table />
        </div>
      </AdvanceTableWrapperEvento>
    </>
  )
}