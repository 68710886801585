import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import menuList1 from 'data/footer/menuList1';
import { bgWhiteIcons } from 'data/socialIcons';
import createMarkup from 'helpers/createMarkup';
import Section from 'components/common/Section';
import Logo from 'assets/img/generic/logo_black.svg';
import IconGroup from 'components/common/icon/IconGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FooterTitle = ({ children }) => (
  <h5 className="text-uppercase text-1100 fw-700 opacity-85 mb-3">{children}</h5>
);

FooterTitle.propTypes = { children: PropTypes.node.isRequired };

const FooterList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map(({ title, to }, index) => (
      <li className="mb-1" key={index}>
        <Link className="text-900 fw-600" to={to}>
          {title}
        </Link>
      </li>
    ))}
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterBlogList = ({ list }) => (
  <ul className="list-unstyled">
    {list.map((blog, index) => (
      <li key={index}>
        <h5 className="fs-0 mb-0">
          <Link className="text-600" to="#!">
            {blog.title}
          </Link>
        </h5>
        <p className="text-600 opacity-50">
          {blog.date} &bull; {blog.read} read{' '}
          {blog.star && (
            <span dangerouslySetInnerHTML={createMarkup('&starf;')} />
          )}
        </p>
      </li>
    ))}
  </ul>
);

FooterBlogList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
      <Section className="pt-8 pb-4 light full-width background-primary">
        <div
          className="position-absolute btn-back-to-top cursor-pointer"
          onClick={scrollToTop}
        >
          <FontAwesomeIcon
            icon="chevron-up"
            transform="rotate-45"
            className="text-600"
          />
        </div>
        <Row>
          <Col className='logo-footer'>
            <img src={Logo}></img>
          </Col>
          <Col lg={4}>
            <FooterTitle>Nossa Missão</FooterTitle>
            <p className="text-900 fw-600">
            Capacitar nossos clientes a alcançar o sucesso por meio da inovação, automação,  
            eficiência e compromisso com a excelência. 
            Estamos empenhados em fornecer soluções de PDV de alta qualidade que simplificam as operações, 
            impulsionam as vendas e melhoram a experiência do cliente. 
            </p>
          </Col>
          <Col className="ps-lg-6 ps-xl-8">
              <Col className="links-footer" xs={6} md={3}>
                <FooterTitle>Institucional</FooterTitle>
                <FooterList list={menuList1} />
                <IconGroup className="mt-4" icons={bgWhiteIcons} />
              </Col>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default FooterStandard;