import React from "react";

export default function FieldValidationMessage({ 
  minValue,
  maxValue,
  fieldName, 
  minLength, 
  maxLength,
  exactSize,
  greaterThan,
  overridenMessage,
  fieldGreaterThan
}) {
  return (
      overridenMessage ? <p>{overridenMessage}</p> :
      <p>O campo "{fieldName}" é obrigatório 
        { minLength && maxLength ?  ` e deve ter entre ${minLength} e ${maxLength} caracteres` : 
          minLength ? ` e deve ter pelo menos ${minLength} caracteres` :
          maxLength ? ` e deve ter no máximo ${maxLength} caracteres` : 
          greaterThan ? ` e deve ser maior que o campo "${fieldGreaterThan}"` : 
          exactSize ? ` e deve ter exatamente ${exactSize} caracteres` : 
          minValue ? ` e deve ser pelo menos ${minValue}` :
          maxValue ? `` : '' }
      </p>
  )
}