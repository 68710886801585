import React from 'react';
import useAuth from 'hooks/useAuth';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import baseController from 'api/base.controller';
import { Link, useNavigate } from 'react-router-dom';
import defaultUserImage from 'assets/img/icons/request-system/user.svg';


const ProfileDropdown = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const imgToUse = auth.userImage ?? JSON.parse(localStorage.getItem('auth')).userImage;

  const handleLogout = async () => {
    try {
      await baseController.post('/Logout', {}, { headers: { 'x-functions-key': process.env.REACT_APP_API_KEY_LOGOUT }});

      setAuth({});
      localStorage.clear();
    } catch (err) { console.log(err?.message); }

    navigate('/');
  }

  return (
    <Dropdown navbar={true} as="li" style={{ marginRight: '20px' }}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        { imgToUse ?
            <Avatar src={ imgToUse } /> :
            <Avatar src={ defaultUserImage } />
        }
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} onClick={handleLogout}>
            Sair
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;