import useAuth from "hooks/useAuth";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import DeleteIcon from "../../../assets/img/icons/request-system/delete.svg";
import ErrorModal from "./errorModal";

export default function DeleteModal({ route, getData, setLoaded, controller }) {
  const { auth } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Ocorreu um erro inesperado");

  const callServer = async () => {
    await controller.delete(route, {
      headers: {
        Authorization: auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    }).then(async () => {
      setModalShow(false);
      setLoaded(false);

      await getData();
    }).catch(e => { 
        if(e && e.response && e.response.data) setErrorMessage(e.response.data);

        setModalShow(false);
        setLoaded(true);

        setShowErrorModal(true);
    });
  }

  return (
    <>
      <Button 
        title='Remover'
        className='delete-button-index'
        onClick={() => {
          setModalShow(true);
        }}
      >
        <img 
          src={DeleteIcon}
          alt='Remover'
          className='button-icon'
        />
      </Button>

      <Modal
        centered
        size="md"
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title 
            className="delete-modal-title"
            id="contained-modal-title-vcenter"
          >
            Confirmação de exclusão
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="delete-modal-highlighted">
            Deseja excluir o registro?
          </p>
          <p className="delete-modal-standart-text">
            Após a remoção não será possível recuperá-lo.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            title="Cancelar"
            variant="secundary"
            onClick={() => setModalShow(false)}
            className="delete-modal-cancel-button"
          >
            Cancelar
          </Button>
          <Button 
            title="Remover"
            variant="danger"
            onClick={async () => await callServer()}
          >
            Remover
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal 
        errorMessage={errorMessage}
        showModal={showErrorModal}
        setShowModal={setShowErrorModal}
      />
    </>
  );
}