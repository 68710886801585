import moment from 'moment';
import tz from 'moment-timezone';
import React, { useState } from 'react';
import Section from 'components/common/Section';
import baseController from 'api/base.controller';
import { Form, Button, Row, Col, FormControl } from 'react-bootstrap';

export const timezone = tz;

const Cta = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitError, setFormSubmitError] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    nome: '',
    email: '',
    assunto: '',
    mensagem: '',
    resolvido: false,
    data: moment().tz("America/Sao_Paulo").toDate()
  });

  const sectionStyle = {
    width: '100vw'
  };

  const whiteText = {
    color: 'white',
    fontSize:'16px',
    marginTop: '15px'
  };

  const leftText = {
    textAlign:'left'
  };

  const textareaStyle = {
    maxHeight: "200px",
    resize: "vertical",
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  const checkFormFields = () => {
    if (formData.assunto === '' || formData.email === '' || formData.mensagem === '' || formData.nome === '')
      return false;

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(!checkFormFields) {
      setFormSubmitError(true);
      setErrorMessage("Por favor, verifique e preencha todos os campos acima.");
      return;
    }

    try {
      await baseController.post('ContatoRecebido', formData);

      setFormData({
        id: '',
        nome: '',
        email: '',
        assunto: '',
        mensagem: '',
        resolvido: false,
        data: moment().tz("America/Sao_Paulo").toDate()
      });
    } catch (error) {
      setFormSubmitError(true);

      setErrorMessage("Ocorreu um erro ao tentar enviar sua solicitação, tente novamente por favor.");
    }
  }

  return (
    <>
      <Section overlay style={sectionStyle} position="center top" className="light bg-dark" id="contact-form">
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <p className="fs-3 fs-sm-4 text-white">
              Contate-nos e faça parte da comunidade Request System.
            </p>
            <form onSubmit={handleSubmit} style={leftText}>
              <Form.Group controlId="formGroupNome">
                <Form.Label style={whiteText}>Nome</Form.Label>
                <Form.Control
                  required
                  name="nome"
                  type="text"
                  minLength={2}
                  maxLength={100}
                  value={formData.nome}
                  onChange={handleInputChange}
                  placeholder="Digite seu nome completo"
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe o seu nome.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupEmail">
                <Form.Label style={whiteText}>Email</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  minLength={2}
                  maxLength={100}
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="exemplo@email.com"
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe um email válido.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupAssunto">
                <Form.Label style={whiteText}>Assunto</Form.Label>
                <Form.Control
                  required
                  type="text"
                  minLength={2}
                  name="assunto"
                  maxLength={100}
                  value={formData.assunto}
                  onChange={handleInputChange}
                  placeholder="Digite o assunto da mensagem"
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe o assunto da mensagem.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formGroupMensagem">
                <Form.Label style={whiteText}>Mensagem</Form.Label>
                <FormControl
                required
                as="textarea"
                name="mensagem"
                aria-label="Mensagem"
                value={formData.mensagem}
                onChange={handleInputChange}
                placeholder="Digite sua mensagem"
                style={textareaStyle} />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe a mensagem.
                </Form.Control.Feedback>
              </Form.Group>
              <br />
              <Button
                variant="outline-light"
                size="lg"
                className="border-2 rounded-pill mt-4 fs-0 py-2"
                onClick={handleSubmit}
              >
                Enviar solicitação
              </Button>
              {formSubmitError && (
                <p style={{ color: "red", marginTop: "10px" }}>{errorMessage}</p>
              )}
            </form>
         
          </Col>
        </Row>
      </Section>
    </>
)};

export default Cta;
