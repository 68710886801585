import React from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function FilterFields({ specificFilter, setSpecificFilter }){
  const handleSpecificFilter = (event) => {
    const newSpecificFilter = { ...specificFilter };

    newSpecificFilter[event.target.name] = event.target.value;

    setSpecificFilter(newSpecificFilter);
  };

  return (
    <>
      <Form.Group controlId="formGroupName">
        <Form.Label>Nome</Form.Label>
        <Form.Control
          name="nome"
          type="text"
          maxLength={100}
          autoComplete="no"
          value={specificFilter.nome}
          placeholder="Nome do Evento"
          onChange={(e) => handleSpecificFilter(e)}
        />
      </ Form.Group>
      <Form.Group controlId="formGroupTipoEvento">
        <Form.Label>Tipo de Evento</Form.Label>
        <Form.Select
          name="tipoEvento"
          aria-label="Tipo do Evento"
          value={specificFilter.tipoEvento}
          onChange={(e) => handleSpecificFilter(e)}
        >
          <option value=""></option>
          <option value="T">Ticket</option>
          <option value="C">Cashless Pós-Pago</option>
          <option value="P">Cashless Pré-Pago</option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="formGroupDataInicio">
        <Form.Label>Data de Início</Form.Label>
        <InputGroup>
          <InputGroup.Text>De</InputGroup.Text>
          <Form.Control
            required
            type="date"
            name="dataInicioInicial"
            placeholder="Data de Início do Evento"
            value={specificFilter.dataInicioInicial}
            selected={specificFilter.dataInicioInicial}
            onChange={(e) => handleSpecificFilter(e)}
          />
        </InputGroup>
        <InputGroup className='filter-final-date'>
          <InputGroup.Text>Até</InputGroup.Text>
          <Form.Control
            required
            type="date"
            name="dataInicioFinal"
            placeholder="Data de Início do Evento"
            value={specificFilter.dataInicioFinal}
            selected={specificFilter.dataInicioFinal}
            onChange={(e) => handleSpecificFilter(e)}
          />
        </InputGroup>
      </ Form.Group>
      <Form.Group controlId="formGroupDataTermino">
        <Form.Label>Data de Encerramento</Form.Label>
        <InputGroup>
          <InputGroup.Text>De</InputGroup.Text>
          <Form.Control
            required
            type="date"
            name="dataTerminoInicial"
            value={specificFilter.dataTerminoInicial}
            selected={specificFilter.dataTerminoInicial}
            placeholder="Data de Encerramento do Evento"
            onChange={(e) => handleSpecificFilter(e)}
          />
        </InputGroup>
        <InputGroup className='filter-final-date'>
          <InputGroup.Text>Até</InputGroup.Text>
          <Form.Control
            required
            type="date"
            name="dataTerminoFinal"
            value={specificFilter.dataTerminoFinal}
            selected={specificFilter.dataTerminoFinal}
            placeholder="Data de Encerramento do Evento"
            onChange={(e) => handleSpecificFilter(e)}
          />
        </InputGroup>
      </ Form.Group>
    </>
  )
}