import useAuth from 'hooks/useAuth';
import Error401 from 'components/pages/errors/Error401';
import EventoDashboard from "../dashboards/evento";
import React, { useState, useEffect } from "react";
import { defaultDashboardRoles } from "../sistema/allowedRoles";

export default function EventoDashboardAuth({idEvento}) {
  const { auth } = useAuth();
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    const isAuthorized = defaultDashboardRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);
  }, []);

  if(!authorized) return (<Error401 />);

  return (<EventoDashboard idEvento={idEvento} />);
}