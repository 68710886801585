import React from 'react';
import CountUp from 'react-countup';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Background from 'components/common/Background';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import image from 'assets/img/icons/spot-illustrations/corner-7.png';


const ResumoEvento = ({ data }) => {
  return (
    <Row className="g-3 mb-3 resumo-row">
      {data.map(stat => (
        <Col key={stat.titulo} sm={3} className="resumo-info">
          <Card className='overflow-hidden dashboard-card-shadow'>
            <Background image={image} className="bg-card" alternativeStyle={true}/>
            <OverlayTrigger
              overlay={ stat.classe.includes('tooltip-info') ? 
                <Tooltip
                  id={'tooltip-top'}
                  style={{ position: 'fixed' }}
                >
                  Total de Vendas / Quantidade de Pedidos
                </Tooltip>
                : <></>
              }
            >
              <Card.Body 
                className="position-relative"
              >
              
                <h6>
                  {stat.titulo}
                </h6>
                <div
                  className={classNames(
                    stat.classe,
                    'display-4 fs-4 mb-2 fw-normal font-sans-serif'
                  )}
                >
                  
                  <CountUp
                    start={0}
                    end={stat.valor}
                    duration={1}
                    suffix={stat.sufixo}
                    prefix={stat.prefixo}
                    separator=","
                    decimals={stat.decimal ? 2 : 0}
                    decimal="."
                  />
                </div>
              </Card.Body>
            </OverlayTrigger>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ResumoEvento;