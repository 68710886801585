import useAuth from 'hooks/useAuth';
import { Card, Button } from 'react-bootstrap';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { cardapioRoles } from '../sistema/allowedRoles';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import MarkAsResolvedButton from '../sistema/markAsResolvedButton';

export default function ContatoRecebidoDetail() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [data, setData] = useState({
    nome: "",
    email: "",
    assunto: "",
    mensagem: ""
  });

  const handleMarkAsResolved = () => {
    navigate(-1);
  }

  const getData = async () => {
    await baseController
      .get(`ContatoRecebido/GetById/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
      .then((response) => {
        setData(response.data);
      });
  };

  useEffect(async () => {
    const isAuthorized = cardapioRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) await getData();

    setLoaded(true);
  }, []);

  const handleClick = () => {
    navigate(-1);
  }

  if(!loaded) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  return(
  <Card className='grid-card'>
    <Card.Header>
      <h1 className='detail-screen-title'>
        Detalhes do contato
      </h1>
    </Card.Header>
    <Card.Body className='table-card-body-in-detail'>
      <p className='detail-prop-title'>Nome:<p className='detail-prop-value'>{data.nome}</p></p>
      <p className='detail-prop-title'>E-mail:<p className='detail-prop-value'>{data.email}</p></p>
      <p className='detail-prop-title'>Assunto:<p className='detail-prop-value'>{data.assunto}</p></p>
      <Card className='detail-message-card'>
        <Card.Title className='detail-message-title'>Mensagem</Card.Title>
        <Card.Body  className='detail-message-value'>
          <Card.Text>{data.mensagem}</Card.Text>
        </Card.Body>
      </Card>
    </Card.Body>
    <Card.Footer className='d-flex justify-content-between'>
      <Button
        title='Voltar'
        variant='danger'
        onClick={handleClick}
        className='border-secundary-button'
      >
        Voltar
      </Button>
      <MarkAsResolvedButton
        setLoaded={setLoaded}
        getData={handleMarkAsResolved}
        route={`ContatoRecebido/MarkAsResolved/${id}`}
      />
    </Card.Footer>
  </Card>
  )
}