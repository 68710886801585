export default function CalculateRowQuantity(itemsPerPage, setItemsPerPage) {
  let tableSize,
        rowRatio;
    const height = window.innerHeight;

    if (height >= 200 && height < 440) rowRatio = 1;
    else if (height >= 440 && height < 630) {
      tableSize = height / 3;
      rowRatio = Math.floor(tableSize / 51);
    } else if (height >= 630 && height <= 880) {
      tableSize = height / 2.25;
      rowRatio = Math.floor(tableSize / 51);
    } else if (height > 880) {
      tableSize = height / 1.75;
      rowRatio = Math.floor(tableSize / 51);
    } else rowRatio = 0;

    if(itemsPerPage !== rowRatio) setItemsPerPage(rowRatio);
}