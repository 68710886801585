import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import ProdutoEdit from '../produtos/edit';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { categoriaRoles } from '../sistema/allowedRoles';
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form, Modal, Button } from 'react-bootstrap';
import FullScreenLoading from '../sistema/fullScreenLoading';
import FieldValidationMessage from '../sistema/fieldValidationMessage';

function adjustDataToCombo(data) {
  return data.map((produto) => {
    return {
      value: produto.id,
      nome: produto.nome,
      descricao: produto.descricao,
      imagem: produto.imagem,
      preco: produto.preco,
      label: produto.nome
    }
  })
}

export default function CategoriaEdit({ isModal, handleModalSubmit, setModalShow, isEdit, categoriaId, className }) {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [comboValues, setComboValues] = useState([]);
  const [authorized, setAuthorized] = useState(false);
  const [produtoModalShow, setProdutoModalShow] = useState(false);
  const [isCliente] = useState(["CM-C", "CM-A"].includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role));
  const [data, setData] = useState({
    id: "",
    nome: "",
    lojaId: null,
    exibicao: "",
    produtos: []
  });

  useEffect(() => {
    const isAuthorized = categoriaRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) getData();
  }, []);

  const getData = async () => {
    setLoading(true);

    if ((id && !isModal) || (isEdit && categoriaId)) {
      const idToUse = !isEdit ? id : categoriaId;
      baseController.get(`Categoria/${idToUse}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => {
          setData(res.data);
          setValue(adjustDataToCombo(res.data.produtos));
        });
    }

    if(isCliente && !id) {
      const newData = { ...data };

      const defaultLoja = await baseController.get('Loja/GetId/ClienteMensal', {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });

      newData.lojaId = defaultLoja.data.id;

      setData(newData);
    }

    baseController.get('Produto/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then((res) => setComboValues(adjustDataToCombo(res.data)))
      .finally(() => setLoading(false));
  };

  const handleProdutoModalSubmit = async (event, produto) => {
    event.preventDefault();

    addProduto(produto);
    setProdutoModalShow(false)
  };

  const addProduto = (produto) => {
    const newData = { ...data };

    newData['produtos'].push(produto);

    setData(newData);
    setValue(adjustDataToCombo(newData['produtos']));
  }

  useEffect(() => {
    const produtos = [];
    const newData = {...data};
    
    value?.forEach((produto) => {
      produto.value ? 
      produtos.push({ id: produto.value, nome: produto.nome, descricao: produto.descricao, imagem: produto.imagem, preco: produto.preco }) : 
      produtos.push({ nome: produto.nome });
    });

    newData['produtos'] = produtos;
    setData(newData);
  }, [value]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);

      return;
    }

    if(isEdit) {
      baseController
        .put('Categoria', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
        .then((res) => handleModalSubmit(event, res.data))
        .finally(() => setLoading(false));
    } else if (isModal) {
      baseController
        .post('Categoria', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
        .then((res) => handleModalSubmit(event, res.data))
        .finally(() => setLoading(false));
    } else {
      id ? 
        baseController.put('Categoria', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
          .then(() => navigate(-1))
          .finally(() => setLoading(false)) : 
        baseController.post('Categoria', data, {
          headers: {
            'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
          }
        })
          .then(() => navigate(-1))
          .finally(() => setLoading(false));
    }
  }
  
  const handleData = (event) => {
    const newData = {...data};

    newData[event.target.name] = event.target.value;

    setData(newData);
  }

  if(loading) return (<FullScreenLoading />)

  if(!authorized) return (<Error401 />);

  return (
    <Card className={className ? className + 'edit-card' : 'edit-card'}>
      <Card.Header>
        <h1 className='edit-form-title'>
          { (id && !isModal) || (isEdit && categoriaId) ? 'Editar categoria' : 'Incluir categoria'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='categoria-form'
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group 
          className="mb-3" 
          controlId="formGroupName"
        >
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.nome}
            placeholder="Nome da categoria"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Nome"}
            />
          </Form.Control.Feedback>
        </ Form.Group>
        <Form.Group className="mb-3" controlId="formGroupNameExibicao">
          <Form.Label>Nome de Exibição</Form.Label>
          <Form.Control
            required
            type="text"
            minLength={2}
            maxLength={100}
            name="exibicao"
            value={data.exibicao}
            placeholder="Nome de Exibição"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Nome de Exibição"}
            />
          </Form.Control.Feedback>
        </ Form.Group>
        <Form.Group 
          controlId="formGroupProdutos"
          className="mb-3 form-group-select-with-button" 
        >
          <Button
            className="form-select-button"
            onClick={() => setProdutoModalShow(true)}
          >
            Novo Produto
          </Button>
          <Form.Label>Produtos</Form.Label>
          <Select
            isMulti
            value={value}
            name="produtos"
            options={comboValues}
            closeMenuOnSelect={false}
            style={{marginTop: '10px'}}
            classNamePrefix="react-select"
            onChange={value => setValue(value)}
            placeholder='Selecione produtos existentes'
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button 
          variant="danger"
          onClick={() => {
            !isModal ? navigate(-1) : setModalShow(false);
          }}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="categoria-form"
        >
          Salvar
        </Button>
      </Card.Footer>
      <Modal
        centered
        size="lg"
        show={produtoModalShow}
        onHide={() => setProdutoModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <ProdutoEdit  
          isModal={true} 
          className="form-in-modal "
          setModalShow={setProdutoModalShow}
          handleModalSubmit={(event, data) =>  handleProdutoModalSubmit(event, data)}
        />
      </Modal>
    </Card>
  )
}