import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ArrowBack from "assets/img/icons/request-system/arrow-back.svg";

export default function BackButton() {
    const navigate = useNavigate();

    return (
        <Button 
            title='Voltar'
            onClick={() => navigate(-1)}
            className='reload-button-index'
        >
          <img 
            alt='Voltar'
            src={ArrowBack}
            className='button-icon'
          />
        </Button>
    );
}