const crypto = require('crypto');

export default function handleDecrypt(value) {
    try {
        const algorithm = 'aes-256-cbc';
        const key = Buffer.from(process.env.REACT_APP_SECURE_KEY);
        const iv = Buffer.from(process.env.REACT_APP_SECURE_IV);

        const decipher = crypto.createDecipheriv(algorithm, key, iv);
        let decrypted = decipher.update(value, 'base64', 'utf-8');
        decrypted += decipher.final('utf-8');
        
        return decrypted;
    }
    catch {
        return "";
    }
}