import React from "react";

export default function FormatCurrency({ number }) {
  const formatter = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'});

  return(
    <>
      {formatter.format(number)}
    </>
  )
}