export const bgWhiteIcons = [
  { icon: ['fab', 'facebook-f'], color: 'facebook', bg: 'white', href: '#!' },
  { icon: ['fab', 'instagram'], color: 'instagram', bg: 'white', href: '#!' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', bg: 'white', href: '#!' }
];

export default [
  { icon: ['fab', 'facebook-f'], color: 'facebook', href: '#!' },
  { icon: ['fab', 'linkedin-in'], color: 'linkedin', href: '#!' },
  { icon: ['fab', 'instagram'], color: 'instagram', href: '#!' },
];