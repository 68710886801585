const pisSituacaoTributariaData = [
  { value: "1", label: "1 - Operação tributável: base de cálculo = valor da operação (alíquota normal - cumulativo/não cumulativo)" },
  { value: "2", label: "2 - Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)" },
  { value: "3", label: "3 - Operação tributável: base de cálculo = quantidade vendida × alíquota por unidade de produto" },
  { value: "4", label: "4 - Operação tributável: tributação monofásica (alíquota zero)" },
  { value: "5", label: "5 - Operação tributável: substituição tributária" },
  { value: "6", label: "6 - Operação tributável: alíquota zero" },
  { value: "7", label: "7 - Operação isenta da contribuição" },
  { value: "8", label: "8 - Operação sem incidência da contribuição" },
  { value: "9", label: "9 - Operação com suspensão da contribuição" },
  { value: "49", label: "49 - Outras operações de saída" },
  { value: "50", label: "50 - Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno" },
  { value: "51", label: "51 - Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno" },
  { value: "52", label: "52 - Operação com direito a crédito: vinculada exclusivamente a receita de exportação" },
  { value: "53", label: "53 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno" },
  { value: "54", label: "54 - Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação" },
  { value: "55", label: "55 - Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação" },
  { value: "56", label: "56 - Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação" },
  { value: "60", label: "60 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno" },
  { value: "61", label: "61 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita não tributada no mercado interno" },
  { value: "62", label: "62 - Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação" },
  { value: "63", label: "63 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno" },
  { value: "64", label: "64 - Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação" },
  { value: "65", label: "65 - Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação" },
  { value: "66", label: "66 - Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação" },
  { value: "67", label: "67 - Crédito presumido: outras operações" },
  { value: "70", label: "70 - Operação de aquisição sem direito a crédito" },
  { value: "71", label: "71 - Operação de aquisição com isenção" },
  { value: "72", label: "72 - Operação de aquisição com suspensão" },
  { value: "73", label: "73 - Operação de aquisição a alíquota zero" },
  { value: "74", label: "74 - Operação de aquisição sem incidência da contribuição" },
  { value: "75", label: "75 - Operação de aquisição por substituição tributária"},
  { value: "98", label: "98 - Outras operações de entrada"},
  { value: "99", label: "99 - Outras operações"}
];

export default pisSituacaoTributariaData;