import useAuth from "hooks/useAuth";
import React, { useState } from "react";
import baseController from "api/base.controller";
import FullScreenLoading from "./fullScreenLoading";
import { Form, Modal, Button } from "react-bootstrap";
import DuplicateIcon from "assets/img/icons/request-system/duplicate.svg";


export default function DuplicateButton({id, getData}) {
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);

  const [data, setData] = useState({
    id: id,
    nome: ''
  });

  const handleDuplicate = async () => {
    setLoading(true);
    
    try {
      await baseController.post(`Loja/Duplicate`, data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });

      await getData();
    } catch(e) { console.log(e); }

    setLoading(false);
    setModalShow(false);
  };

  const handleChange = (event) => {
    const newData = { ...data };

    newData[event.target.name] = event.target.value;

    setData(newData);
  };

  if(loading) return (<FullScreenLoading />);
  
  return (
    <>
      <Button 
        title='Duplicar'
        className={'dashboard-button-index'}
        onClick={() => setModalShow(true)}
      >
        <img
          alt='Duplicar'
          src={DuplicateIcon}
          className='button-icon'
        />
      </Button>

      <Modal
        centered
        size="sm"
        show={modalShow}
        onHide={handleModalClose}
        aria-labelledby="duplicate-modal-title"
      >
      <Modal.Header closeButton>
        <Modal.Title
          id="duplicate-modal-title"
          className="delete-modal-title"
        >
          Duplicar Loja
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Nome da Loja</Form.Label>
            <Form.Control
              required
              type="text"
              name="nome"
              value={data.nome}
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          title="Cancelar"
          variant="secundary"
          onClick={handleModalClose}
          className="delete-modal-cancel-button"
        >
          Cancelar
        </Button>
        <Button 
          title="Duplicar"
          variant="primary"
          onClick={handleDuplicate}
        >
          Duplicar
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  )
}