import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function ErrorModal({ errorMessage, showModal, setShowModal }) {
  return (
    <Modal
      centered
      size="md"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header 
        closeButton
        className="error-modal-title-container"
      >
        <Modal.Title 
          className="error-modal-title"
          id="contained-modal-title-vcenter"
        >
          Erro
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="delete-modal-highlighted">
          Ocorreu um erro ao tentar realizar a ação
        </p>
        <p className="delete-modal-standart-text">
          {errorMessage}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          title="Ok"
          variant="danger"
          onClick={() => setShowModal(false)}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}