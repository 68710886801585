import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { pessoaRoles } from '../sistema/allowedRoles';
import { useNavigate, useParams } from "react-router-dom";
import MaskedFormControl from 'react-bootstrap-maskedinput';
import FullScreenLoading from '../sistema/fullScreenLoading';
import FieldValidationMessage from '../sistema/fieldValidationMessage';
import estados from '../sistema/estados';

function adjustDataToCombo(data) {
  return data.map((pessoa) => {
    return generatePessoaData(pessoa);
  })
}

function adjustUniqueDataToCombo(pessoa) {
  return generatePessoaData(pessoa);
}

function generatePessoaData(pessoa) {
  if(pessoa)
    return {
      value: pessoa.id,
      label: pessoa.nome
    }
}

export default function PessoaEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [estado, setEstado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tipoPessoa, setTipoPessoa] = useState("F");
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [valuePessoa, setValuePessoa] = useState(null);
  const [comboPessoaValues, setComboPessoaValues] = useState(null);
  const [telefoneMask, setTelefoneMask] = useState("(11) 1111-1111");
  const [data, setData] = useState({
    nome: "",
    razaoSocial: "",
    email: "",
    tipo: "F",
    rg: "",
    cpf: "",
    cnpj: "",
    inscricaoEstadual: "",
    nomeFantasia: "",
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    tokenFocus: "",
    estado: 35,
    telefone: "",
    pessoaResponsavelId: null
  });

  useEffect(() => {
    const isAuthorized = pessoaRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) getData();
  }, []);

  useEffect(() => {
    const pessoaId = valuePessoa?.value;
    const newData = {...data};
    
    newData['pessoaResponsavelId'] = pessoaId;
    newData['pessoaResponsavel'] = null;

    setData(newData);
  }, [valuePessoa]);

  const getData = async () => {
    setLoading(true);

    if (id) {
      baseController.get(`Pessoa/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => {
          if (res.data.telefone.length === 11) setTelefoneMask("(11) 11111-1111");
        
          setData(res.data);
          setTipoPessoa(res.data.tipo);
          setEstado(estados.filter(item => item.value === res.data.estado)[0]);

          setValuePessoa(adjustUniqueDataToCombo(res.data.pessoaResponsavel));
        });
    }

    baseController.get(`Pessoa/GetAll`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    }).then((res) => {
      setComboPessoaValues(adjustDataToCombo(res.data));
    }).finally(() => setLoading(false));
  };

  useEffect(() => {
    const telefoneOnlyNumbers = data.telefone.replace(/[^0-9]/g, ""),
          thirdDigit = telefoneOnlyNumbers.at(2);
    
    if(thirdDigit !== '9') setTelefoneMask("(11) 1111-1111");
    else setTelefoneMask("(11) 11111-1111");
  }, [data.telefone]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const newData = {...data};
    const form = event.currentTarget;
    const fieldsToRemoveMask = ["cpf", "cnpj", "rg", "telefone", "inscricaoEstadual"];

    fieldsToRemoveMask.forEach((field) => {
      newData[field] = newData[field]?.replace(/([^0-9A-Za-z])/g, "");
    });

    if (!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return;
    }
    
    id ? 
      baseController.put('Pessoa', newData, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) : 
      baseController.post('Pessoa', newData, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }
  
  const handleData = (event) => {
    const newData = {...data};

    if (event.target.name === 'tipo')
    {
      if(event.target.value === "F") 
      {
        newData.cnpj = "";
        newData.razaoSocial = "";
        newData.nomeFantasia = "";
        newData.pessoaResponsavelId = null;
      } else if (event.target.value === "J") {
        newData.rg = "";
        newData.cpf = "";
        newData.pessoaResponsavelId = null;
      }

      setValuePessoa(null);
      setTipoPessoa(event.target.value);
    } 

    newData[event.target.name] = event.target.value;
    setData(newData);
  }

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return ( <Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar cadastro de pessoa' : 'Incluir nova pessoa'}
        </h1>
      </Card.Header>
        <Card.Body
          as={Form}
          noValidate
          id='pessoa-form'
          validated={validated}
          onSubmit={handleSubmit}
          className='edit-card-body'
        >
          <Form.Group className="mb-3" controlId="formNome">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              name="nome"
              type="text"
              minLength={2}
              maxLength={100}
              autoComplete="no"
              value={data.nome}
              placeholder="Nome da pessoa"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={100}
                fieldName={"Nome"}
              />
            </Form.Control.Feedback>
          </Form.Group>             
          <Form.Group className="mb-3" controlId="formEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              name="email"
              type="text"
              minLength={10}
              maxLength={70}
              autoComplete="no"
              value={data.email}
              onChange={(e) => handleData(e)}
              placeholder="usuario@exemplo.com"
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={10}
                maxLength={70}
                fieldName={"Email"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTipo">
            <Form.Label>Tipo de Pessoa</Form.Label>
            <Form.Select 
              required
              name="tipo"
              aria-label=""
              value={data.tipo}
              onChange={(e) =>{ setValidated(false); handleData(e) }}
            >
              <option value="F">Física</option>
              <option value="J">Jurídica</option>    
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Tipo de Pessoa"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          { tipoPessoa === 'F' ? 
              (
                <>
                  <Form.Group className="mb-3" controlId="formCpf">
                    <Form.Label>CPF</Form.Label>
                    <MaskedFormControl
                      name="cpf"
                      type="text"
                      value={data.cpf}
                      placeholder="CPF"
                      autoComplete="no"
                      mask="111.111.111-11"
                      required={tipoPessoa === "F"}
                      onChange={(e) => handleData(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        exactSize={11}
                        fieldName={"CPF"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formRg">
                    <Form.Label>RG</Form.Label>
                    <MaskedFormControl
                      name="rg"
                      type="text"
                      value={data.rg}
                      placeholder="RG"
                      autoComplete="no"
                      mask="11.111.111-*"
                      required={tipoPessoa === "F"}
                      onChange={(e) => handleData(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        exactSize={9}
                        fieldName={"RG"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : 
              tipoPessoa === 'J' ? 
              (
                <>
                  <Form.Group className="mb-3" controlId="formFantasia">
                    <Form.Label>Nome Fantasia</Form.Label>
                    <Form.Control
                      type="text"
                      minLength={2}
                      maxLength={100}
                      autoComplete="no"
                      name="nomeFantasia"
                      value={data.nomeFantasia}
                      placeholder="Nome Fantasia"
                      required={tipoPessoa === "J"}
                      onChange={(e) => handleData(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        minLength={2}
                        maxLength={100}
                        fieldName={"Nome Fantasia"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>                
                  <Form.Group className="mb-3" controlId="formCnpj">
                    <Form.Label>CNPJ</Form.Label>
                    <MaskedFormControl
                      name="cnpj"
                      type="text"
                      value={data.cnpj}
                      autoComplete="no"
                      placeholder="CNPJ"
                      mask="11.111.111/1111-11"
                      required={tipoPessoa === "J"}
                      onChange={(e) => handleData(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        exactSize={14}
                        fieldName={"CNPJ"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formInscricaoEstadual">
                    <Form.Label>Inscrição Estadual</Form.Label>
                    <MaskedFormControl
                      name="inscricaoEstadual"
                      type="text"
                      value={data.inscricaoEstadual}
                      autoComplete="no"
                      placeholder="Inscrição Estadual"
                      mask="111.111.111.111"
                      required={tipoPessoa === "J"}
                      onChange={(e) => handleData(e)}
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        exactSize={12}
                        fieldName={"Inscrição Estadual"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formSocial">
                    <Form.Label>Razão Social</Form.Label>
                    <Form.Control
                      type="text"
                      minLength={2}
                      maxLength={100}
                      autoComplete="no"
                      name="razaoSocial"
                      value={data.razaoSocial}
                      required={tipoPessoa === "J"}
                      onChange={(e) => handleData(e)}
                      placeholder="Nome da razão social da empresa"
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        minLength={2}
                        maxLength={100}
                        fieldName={"Razão Social"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formSocial">
                    <Form.Label>Token Focus</Form.Label>
                    <Form.Control
                      type="text"
                      minLength={2}
                      maxLength={50}
                      autoComplete="no"
                      name="tokenFocus"
                      value={data.tokenFocus}
                      required={tipoPessoa === "J"}
                      onChange={(e) => handleData(e)}
                      placeholder="Token para Emissão de Nota Fiscal com a Focus"
                    />
                    <Form.Control.Feedback type="invalid">
                      <FieldValidationMessage
                        minLength={2}
                        maxLength={100}
                        fieldName={"Razão Social"}
                      />
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : 
              (<></>) 
            }
          <Form.Group 
            controlId="formPessoaResponsavelId"
            className="mb-3 form-group-select-with-button"
          >
            <Form.Label>Responsável</Form.Label>
            <Select
              isMulti={false}
              closeMenuOnSelect
              value={valuePessoa}
              name="pessoaResponsavelId"
              options={comboPessoaValues}
              classNamePrefix="react-select"
              placeholder="Selecione o responsável"
              onChange={value => setValuePessoa(value)}
              className={validated && data.pessoaResponsavelId === null ? 'form-control is-invalid select-loja-event-form' : 'select-pessoas-loja-form'}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                fieldName={"Responsável"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formTel">
            <Form.Label>Telefone</Form.Label>
            <MaskedFormControl
              required
              type="text"
              minLength={10}
              maxLength={11}
              name="telefone"
              autoComplete="no"
              mask={telefoneMask}
              value={data.telefone}
              onChange={(e) => handleData(e)}
              placeholder="Número de Telefone"
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={10}
                maxLength={11}
                fieldName={"Telefone"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formCep">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={9}
              maxLength={9}
              name="cep"
              autoComplete="no"
              value={data.cep}
              placeholder="CEP"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={9}
                maxLength={9}
                fieldName={"CEP"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formLogradouro">
            <Form.Label>Logradouro</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={5}
              maxLength={200}
              name="logradouro"
              autoComplete="no"
              value={data.logradouro}
              placeholder="Logradouro"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={5}
                maxLength={200}
                fieldName={"Logradouro"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formNumero">
            <Form.Label>Número</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={1}
              maxLength={100}
              name="numero"
              autoComplete="no"
              value={data.numero}
              placeholder="Número"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={1}
                maxLength={100}
                fieldName={"Número"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formComplemento">
            <Form.Label>Complemento</Form.Label>
            <Form.Control
              type="text"
              maxLength={100}
              name="complemento"
              autoComplete="no"
              value={data.complemento}
              placeholder="Complemento"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                maxLength={100}
                fieldName={"Complemento"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBairro">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={1}
              maxLength={100}
              name="bairro"
              autoComplete="no"
              value={data.bairro}
              placeholder="Bairro"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={1}
                maxLength={100}
                fieldName={"Bairro"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formCidade">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              required
              type="text"
              minLength={1}
              maxLength={100}
              name="cidade"
              autoComplete="no"
              value={data.cidade}
              placeholder="Cidade"
              onChange={(e) => handleData(e)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={1}
                maxLength={100}
                fieldName={"Cidade"}
              />
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formEstado">
            <Form.Label>Estado</Form.Label>
            <Select
              value={estado}
              closeMenuOnSelect={true}
              name='estado'
              options={estados}
              classNamePrefix='react-select'
              placeholder='Selecione o Estado'
              onChange={(value) => setEstado(value)}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={1}
                maxLength={100}
                fieldName={"Estado"}
              />
            </Form.Control.Feedback>
          </Form.Group>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button 
          type="submit" 
          variant="danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="pessoa-form"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}