import React from "react";
import useAuth from 'hooks/useAuth';
import { Button } from "react-bootstrap";
import baseController from "api/base.controller";
import CheckIcon from 'assets/img/icons/request-system/check.svg';

export default function MarkAsResolvedButton({ route, getData, setLoaded }) {
  const { auth } = useAuth();

  const markAsResolved = async () => {
    setLoaded(false);

    await baseController.put(route, {}, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });

    await getData();

    setLoaded(true);
  }
  
  return (
    <Button 
      title='Marcar como resolvido'
      className='detail-button-index'
      onClick={markAsResolved}
    >
      <img
        src={CheckIcon}
        alt='Marcar como resolvido.'
        className='button-icon'
      />
    </Button>
  )
}