import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { cardapioRoles } from '../sistema/allowedRoles';
import { Card, Button, Accordion} from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';

export default function CardapioDetail() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState(false);
  const [data, setData] = useState({
    nome: "",
    categorias: []
  });
  const [loaded, setLoaded] = useState(false);

  const getData = async () => {
    await baseController
      .get(`Cardapio/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
      .then((response) => {
        setData(response.data);
      });
  };

  useEffect(async () => {
    const isAuthorized = cardapioRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) await getData();

    setLoaded(true);
  }, []);

  const handleClick = () => {
    navigate(-1);
  }

  if(!loaded) return (<FullScreenLoading />);

  if(!authorized) return (<Error401 />);

  return(
  <Card>
    <Card.Header>
      <h1 className='detail-screen-title'>
        Detalhes do cardápio
      </h1>
    </Card.Header>
    <Card.Body className='detail-card-body'>
      <Card>
        <Card.Body>
          <p>Nome: {data.nome}</p>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          Categorias
        </Card.Header>
        <Card.Body>
          <Accordion>
            {data.categorias.map((categoria) => {
                return (
                  <Accordion.Item 
                    key={categoria.id}
                    eventKey={categoria.id}
                  >
                    <Accordion.Header>
                      {categoria.exibicao}
                    </Accordion.Header>
                    <Accordion.Body className='cardapio-detail-categoria-accordion'>
                      {categoria.produtos.map((produto) => {
                        return (
                        <Card 
                          key={produto.id} 
                          className='cardapio-detail-produto-card'
                        >
                          <Card.Img 
                            variant='top' 
                            src={produto.imagem} 
                            className='cardapio-detail-produto-card-image'
                          />
                          <Card.Body className='cardapio-detail-produto-card-body'>
                              <Card.Title>{produto.nome}</Card.Title>
                              <Card.Text>{produto.descricao}</Card.Text>
                          </Card.Body>
                        </Card>
                        )
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                )
            })}
          </Accordion>
        </Card.Body>
      </Card>
    </Card.Body>
    <Card.Footer>
      <Button
        title='Voltar'
        variant='secundary'
        onClick={handleClick}
        className='border-secundary-button'
      >
        Voltar
      </Button>
    </Card.Footer>
  </Card>
  )
}