import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DetailIcon from "assets/img/icons/request-system/detail.svg";

export default function DetailButton({ route, gridData, dashboardClass = ''}) {
  const navigate = useNavigate();
  const [className, setClassName] = useState('detail-button-index');

  useEffect(() => {
    setClassName('detail-button-index ' + dashboardClass);
  }, []);

  return (
    <Button
      className={className}
      title='Detalhamento de Pedidos'
      onClick={() => { navigate(route, { state: gridData }) }}
    >
      <img
        src={DetailIcon}
        alt='Detalhes'
        className='button-icon'
      />
    </Button>
  )
}