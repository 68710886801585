const icmsOrigemData = [
  { value: "0", label: "0 - Nacional" },
  { value: "1", label: "1 - Estrangeira (importação direta)" },
  { value: "2", label: "2 - Estrangeira (adquirida no mercado interno)" },
  { value: "3", label: "3 - Nacional com mais de 40% de conteúdo estrangeiro" },
  { value: "4", label: "4 - Nacional produzida através de processos produtivos básicos" },
  { value: "5", label: "5 - Nacional com menos de 40% de conteúdo estrangeiro" },
  { value: "6", label: "6 - Estrangeira (importação direta) sem produto nacional similar" },
  { value: "7", label: "7 - Estrangeira (adquirida no mercado interno) sem produto nacional similar" }
];

export default icmsOrigemData;