import QRCode from 'qrcode.react';
import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { Button } from "react-bootstrap";
import handleEncrypt from "./handleEncrypt";
import QrCodeIcon from "assets/img/icons/request-system/qrcode.svg";

export default function QrCodeButton({ value }) {
    const primaryColor = '#F2D06B';
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        setShowModal(true);
    }

    return (
        <>
            <Button
            title='Gerar QRCode'
            onClick={handleClick}
            className='detail-button-index'
            >
                <img
                    src={QrCodeIcon}
                    alt='Gerar QRCode'
                    className='button-icon'
                />
            </Button>
            <Modal
                centered
                size="lg"
                show={showModal ?? false}
                onHide={() => setShowModal(false)}
                onExit={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header
                    closeButton
                    closeLabel='Fechar'
                    className='qr-code-modal-header'
                >
                    <Modal.Title
                    className='qr-code-modal-title'
                    id='contained-modal-title-vcenter'
                    >
                    QRCode
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    className='qr-code-in-modal'
                >
                    <QRCode  
                    size={540}
                    includeMargin
                    bgColor={primaryColor}
                    value={handleEncrypt(value)}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}