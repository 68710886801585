import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AppContext from 'context/Context';
import { topNavbarBreakpoint } from 'config';
import React, { useContext, useEffect} from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Logo from 'assets/img/generic/logo_color.svg';
import LoginButton from '../sistema/loginButton';
import handleNavbarTransparency from 'helpers/handleNavbarTransparency';

const NavbarStandard = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      fixed="top"
      expand={topNavbarBreakpoint}
      variant={isDark ? 'light' : 'dark'}
      className={classNames('navbar-standard navbar-theme', { 'bg-100' : isDark })}
    >
      <Container>
        <Navbar.Brand as={Link} to="/"  className="logo-navbar">
          <img src={Logo}></img>
        </Navbar.Brand>
        <LoginButton />
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
