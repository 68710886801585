import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';

import BasicECharts from 'components/common/BasicEChart';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import generateRandomHexColor from 'components/pages/sistema/randomColorGenerator';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency", 
  currency: "BRL"
});

const getOptions = (data, radius) => {
  const total = formatter.format(data.reduce((soma, item) => soma + item.value, 0));
  return ({
  color: data.map(item => item.color),

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`
  },
  series: [
    {
      name: total,
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: true,
        position: 'center',
        formatter: '{a}',
        fontSize: total < 1000000 ? 23 : 
                  total < 10000000 ? 19 : 16,
        color: getColor('dark')
      },
      data
    }
  ]
})};

const FormaPagamentoItem = ({ item, index, total }) => {
  const { name, value, color } = item;

  const percentage = ((value * 100) / total).toFixed(1);
  return (
    <Flex
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
    >
      <p className="mb-1 vendas-formapagamento-descricao">
        <FontAwesomeIcon
          icon="circle"
          className='me-2'
          color={color}
        />
        {name}
      </p>
      <p className="vendas-formapagamento-valor">
        {formatter.format(value)}
      </p>
      <p className="vendas-formapagamento-porcentagem">{percentage}%</p>
    </Flex>
  );
};

const VendaFormaPagamento = ({ data, radius }) => {
  const total = data.reduce((acc, val) => val.total + acc, 0);

  data = data.map((item) => {
    return { 
      name: item.formaPagamentoNome,
      value: item.total,
      color: generateRandomHexColor()
    };
  });

  return (
    <Card className="h-md-100 dashboard-card-shadow"
      style={{
        backgroundImage: `url(${bg1})`,
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <Card.Header>
        <h4 className="mt-2 ms-2">Vendas por Forma de Pagamento</h4>
      </Card.Header>
      <Card.Body>
        <Row className="mt-3" style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around"
        }}>
            <Col xs="auto" style={{
              fontWeight: "bold",
              marginTop: "20px"
            }}>
              <div className="ps-0">
                <BasicECharts
                  echarts={echarts}
                  options={getOptions(data, radius)}
                  style={{ width: '15rem', height: '15rem' }}
                />
              </div>
            </Col>
        </Row>
        <Row className='mt-3'>
          <Col xxl className="pe-2"
              style={{
                  display: "flex",
                  marginTop: "20px",
                  flexDirection: "column",
                  justifyContent: "space-between",
              }}
            >
            {data.map((item, index) => (
              <FormaPagamentoItem
                item={item}
                key={index}
                index={index}
                total={total}
              />
            ))}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

FormaPagamentoItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

VendaFormaPagamento.propTypes = {
  data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired
};

export default VendaFormaPagamento;