import useAuth from "hooks/useAuth";
import Error401 from "components/pages/errors/Error401";
import React, { Outlet, Navigate, useLocation } from "react-router-dom";

export default function RequireAuth({ allowedRoles }) {
  const { auth } = useAuth();
  const location = useLocation();

  return (
    allowedRoles.includes(auth?.role ?? JSON.parse(localStorage.getItem('auth')).role) ?
      <Outlet /> :
      auth?.user ?? localStorage.getItem('auth', auth).user ?
        <Error401 /> :
        <Navigate 
          replace
          to="/login"
          state={{ from: location }}
        />
  );
}