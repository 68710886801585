import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import classNames from 'classnames';
import steps from './edit.files/steps';
import Flex from 'components/common/Flex';
import LojaForm from './edit.files/storeForm';
import EventForm from './edit.files/eventForm';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useEffect, useState } from 'react';
import { eventoRoles } from '../sistema/allowedRoles';
import IconButton from 'components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import ParticipantForm from './edit.files/participantForm';
import FullScreenLoading from '../sistema/fullScreenLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Form, Nav, Button, ProgressBar } from 'react-bootstrap';

function CheckFieldValidity(id) {
  const field = document.getElementById(id);

  return field.checkValidity();
}

function FixValueToDatePicker(date) {
  const regex = /[+-][0-9]{2}:[0-9]{2}/g;
  return date.replace(regex, '');
}

export default function EventoEdit() {
  const navItems = steps,
        variant = "pills",
        progressBar = true,
        { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    nome: "",
    tipo: "T",
    dataInicio: "",
    dataTermino: "",
    pessoaResponsavel: null,
    pessoaResponsavelId: "",
    lojas: [],
    pessoas: [],
    eventoLojaCardapios: [],
    eventoLojaPontoVendas: [],
    eventoLojaFormaPagamentos: [],
    eventoLojaInformacoesAdicionais: []
  });
  const [loaded, setLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const onNextStep = () => {
    const nextStep = step + 1;

    switch(step) {
      case 1: {
        const stepOneFieldsIds = [
          "formGroupName",
          "formGroupTipoEvento",
          "formGroupDataInicio",
          "formGroupDataTermino"
        ];
        let stepHasErrors = false;

        stepOneFieldsIds.forEach((id) => {
          if(!CheckFieldValidity(id)) stepHasErrors = true;
          if(id === "formGroupDataTermino") new Date(data.dataInicio).getTime() > new Date(data.dataTermino).getTime() ? stepHasErrors = true : null;
        });

        setValidated(stepHasErrors);
        
        if(!stepHasErrors) setStep(nextStep);

        break;
      }
      case 2: {
        let stepHasErrors = false;

        if(data.pessoas.length === 0) stepHasErrors = true;

        setValidated(stepHasErrors);

        if(!stepHasErrors) setStep(nextStep);

        break;
      }
    }
  };

  const handleSubmitToServer = async (event) => {
    setLoaded(false);
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoaded(true);

      return;
    }

    const headers = { 'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token }

    id ?
      await baseController.put('Evento', data, {headers}) :
      await baseController.post('Evento', data, {headers})

    setLoaded(true);
    navigate(-1);
  };

  const handleData = (event, type) => {
    const newData = {...data};
    
    switch(type) {
      case 'switch':
          newData[event.target.name] = event.target.checked;
          break;
      default:
          newData[event.target.name] = event.target.value;
          break;
    }

    setData(newData);
  };

  const handleNavs = targetStep => {
      if (targetStep < step) setStep(targetStep);
      else onNextStep();
  };

  useEffect(async () => {
    const isAuthorized = eventoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized && id) {
      setLoaded(false);

      await baseController.get(`Evento/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => {
          res.data.dataInicio = FixValueToDatePicker(res.data.dataInicio);
          res.data.dataTermino = FixValueToDatePicker(res.data.dataTermino);
          
          setData(res.data);
        })
        .finally(() => setLoaded(true));
    } else setLoaded(true);
  }, []);

  if(!loaded) return (<FullScreenLoading />)

  if(!authorized) return (<Error401 />);
  
  return (
    <Card
      as={Form}
      noValidate
      id="event-form"
      validated={validated}
      className="edit-card"
      onSubmit={(event) => handleSubmitToServer(event)}
    >
      <Card.Header
        className='bg-light px-4 py-3'
      >
        <Nav className="edit-wizard-pills" variant={variant}>
          {navItems.map((item, index) => (
            <NavItemPill
              step={step}
              key={item.label}
              icon={item.icon}
              index={index + 1}
              label={item.label}
              handleNavs={handleNavs}
            />
          ))}
        </Nav>
      </Card.Header>
      {progressBar && <ProgressBar now={step * 20} style={{ height: 4 }} />}
      <Card.Body className="fw-normal px-md-6 py-4">
        { step === 1 && loaded ? (
          <EventForm
            data={data}
            setData={setData}
            validated={validated}
            handleData={handleData}
          />) : (<></>)
        }
        {step === 2 && (
          <ParticipantForm 
            data={data}
            setData={setData}
            validated={validated}
          />
        )}
        {step === 3 && (
          <LojaForm 
            data={data}
            setData={setData}
            validated={validated}
          />
        )}
      </Card.Body>
      <Card.Footer
        className={classNames('px-md-6 bg-light', 'd-flex')}
      >
        <Button 
          type="button"
          variant="danger" 
          style={{marginRight: '10px'}}
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
        <div className='ms-auto wizard-navigate-wrapper'>
          <IconButton
            variant="link"
            iconAlign="left"
            icon='chevron-left'
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold button-margin-right', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Voltar
          </IconButton>
          <IconButton
            variant="primary"
            iconAlign="right"
            icon="chevron-right"
            transform="down-1 shrink-4"
            className={step === 3 ? 'px-5 d-none' : 'px-5'}
            onClick={() => {
              handleNavs(step + 1)
            }}
          >
            Avançar
          </IconButton>
          <IconButton
            icon="save"
            type="submit"
            iconAlign="right"
            variant="primary"
            transform="down-1 shrink-4"
            className={step === 3 ? "ms-auto px-5 " : "ms-auto px-5 d-none"}
          >
            Salvar
          </IconButton>
        </div>
      </Card.Footer>
    </Card>
  );
}

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 3 ? step > index : step > 2,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

EventoEdit.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;