import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import React, { useState } from "react";
import baseController from "api/base.controller";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import toastDefaultOptions from '../sistema/toastDefaultOptions';
import DetailIcon from "assets/img/icons/request-system/detail.svg";
import FieldValidationMessage from '../sistema/fieldValidationMessage';

const PedidoInformacoesFiscaisModal = ({ fiscalData, data, eventoId, id }) => {
  const { auth } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [justificativaModalShow, setJustificativaModalShow] = useState(false);
  const [object, setObject] = useState({
    justificativa: ''
  });

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleJustificativaModalClose = () => {
    setModalShow(true);
    setJustificativaModalShow(false);
  };

  const handleCancelar = () => {
    setModalShow(false);
    setJustificativaModalShow(true);
  };

  const doCancelNFCe = async () => {
    try {
      const response = await baseController.get(`Pedido/CancelNFCe/${eventoId}?pedidoId=${id}&justificativa=${object.justificativa}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });
  
      const cancelled = response.data;

      if(!cancelled)
        toast.error("Não foi possível cancelar a NFCe", toastDefaultOptions);
      else {
        toast.success("NFCe cancelada com sucesso.", toastDefaultOptions);

        setJustificativaModalShow(false);
        setModalShow(false);
      }
    } catch { toast.error("Erro inesperado ao cancelar NFCe", toastDefaultOptions); }
  }

  const handleData = (event) => {
    const newData = {...object};

    newData[event.target.name] = event.target.value;
    
    setObject(newData);
  };

  return (
    <>
      <Button
        title='Detalhes Nota Fiscal'
        className='detail-button-index'
        onClick={() => setModalShow(true)}
      >
        <img
          src={DetailIcon}
          alt='Detalhes'
          className='button-icon'
        />
      </Button>
      <Modal
        centered
        size="lg"
        show={modalShow}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          closeButton
          className="qr-code-modal-header"
        >
          <Modal.Title
            className="qr-code-modal-title"
            id="contained-modal-title-vcenter"
          >
            Informações Fiscais
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row style={{
              padding: 10
            }}>
              <Col>
                <div className="fiscal-data-label">
                  Chave NFe
                </div>
                {fiscalData?.chaveNFe}
              </Col>
            </Row>
            <Row style={{
              padding: 10
            }}
            >
              <Col>
                <div className="fiscal-data-label">Série</div>
                {fiscalData?.serie}
              </Col>
              <Col>
                <div className="fiscal-data-label">Número</div>
                {fiscalData?.numero}
              </Col>
            </Row>
            <Row style={{
              padding: 10
            }}
            >
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fiscal-data-button"
                  href={process.env.REACT_APP_FOCUS_API_URL + fiscalData?.caminhoDanfe}
                >Visualizar Danfe</a>
              </Col>
              <Col>
              {
                Math.abs(new Date() - new Date(data)) <= (120 * 60 * 1000) ?
                <Button
                  variant='danger'
                  title='Cancelar NFCe'
                  onClick={handleCancelar}
                  className='border-secundary-button'
                >
                  Cancelar NFCe
                </Button>
                : <></>
              }
              </Col>
            </Row>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="md"
        show={justificativaModalShow}
        onHide={handleJustificativaModalClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header
          closeButton
          className="qr-code-modal-header"
        >
          <Modal.Title
            className="qr-code-modal-title"
            id="contained-modal-title-vcenter"
          >
            Justificativa do Cancelamento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Control
              rows={4}
              required
              type="text"
              as="textarea"
              minLength={15}
              maxLength={255}
              name="justificativa"
              value={data.justificativa}
              onChange={(e) => handleData(e)}
              placeholder="Justificativa do Cancelamento"
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={2}
                maxLength={200}
                fieldName={"Nome"}
              />
            </Form.Control.Feedback>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            title='Cancelar NFCe'
            onClick={doCancelNFCe}
            className='border-secundary-button'
          >
            Confirmar Cancelamento
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default React.memo(PedidoInformacoesFiscaisModal);