import PropTypes from 'prop-types';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import baseController from 'api/base.controller';
import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { useRef, useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import handleDecrypt from 'components/pages/sistema/handleDecrypt';

const LoginForm = ({ hasLabel, layout }) => {
  const userRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, setAuth } = useAuth();
  const [loaded, setLoaded] = useState(true);
  const fromUrl = location.state?.from?.pathname || "/";

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const [formData, setFormData] = useState({
    nome: '',
    senha: '',
    remember: false
  });
  
  const handleSubmit = async (e) => {
    setLoaded(false);
    e.preventDefault();

    try {
      const response = await baseController.post('Login', formData, {
        headers: { 'x-functions-key': process.env.REACT_APP_API_KEY_LOGIN }
      });

      setAuth({ 
        user: formData?.nome,
        userImage: response?.data?.imageUri,
        role: handleDecrypt(response?.data?.role),
        token: response?.headers?.getAuthorization(),
        pessoaResponsavelId: handleDecrypt(response?.data?.pessoaResponsavelId)
      });
    } catch (err) {
      toast.error(`${e.response?.data?.message ?? 'Login Inválido'}`, {
        theme: 'colored',
        position: 'top-right'
      });
    }

    setLoaded(true);
  };

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));

    if(auth.user) {
      if (fromUrl !== "/")
          return navigate(fromUrl, { replace: true });
        
      switch(auth.role) 
      {
        case 'CM-C':
        case 'LE-C':
          navigate('/cardapio');
          break;
        default:
          navigate('/home');
          break;
      }
    }
  }, [auth])

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Usuário</Form.Label>}
        <Form.Control
          name="nome"
          ref={userRef}
          value={formData.nome}
          onChange={handleFieldChange}
          placeholder={!hasLabel ? 'Usuário' : ''}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Senha</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Senha' : ''}
          value={formData.senha}
          name="senha"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Manter Conectado?
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Esqueceu a Senha?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.nome || !formData.senha}
        >
          Entrar
        </Button>
      </Form.Group>

      { !loaded ? <FullScreenLoading /> : <></>}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;