const crypto = require('crypto');

export default function handleEncrypt(value) {
    try {
        const algorithm = 'aes-256-cbc';
        const key = Buffer.from(process.env.REACT_APP_SECURE_KEY);
        const iv = Buffer.from(process.env.REACT_APP_SECURE_IV);

        const cipher = crypto.createCipheriv(algorithm, key, iv);
        let encrypted = cipher.update(value, 'utf-8', 'base64');
        encrypted += cipher.final('base64');
        
        return encrypted;
    }
    catch {
        return "";
    }
}