import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../../assets/img/icons/request-system/dashboard.svg";


export default function DashboardButton({ route, aditionalClasses }) {
  const navigate = useNavigate();

  return (
    <Button 
      title='Dashboard'
      className={aditionalClasses ? aditionalClasses + ' dashboard-button-index' : 'dashboard-button-index'}
      onClick={() => { navigate(route) }}
    >
      <img 
        src={DashboardIcon}
        alt='Recarregar'
        className='button-icon'
      />
    </Button>
  )
}