import AppContext from 'context/Context';
import React, { useContext } from 'react';
import Section from 'components/common/Section';
import { Button, Col, Row } from 'react-bootstrap';
import { Typewriter } from 'react-simple-typewriter';
import bg1 from 'assets/img/backgrounds/inicial_background.jpg';
import maquina from 'assets/img/generic/maquina-banner-home.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Hero = () => {
  const { config: { isRTL } } = useContext(AppContext);

  const scrollToContactForm = () => {
    const contactForm = document.getElementById('contact-form');
    contactForm.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Section
      overlay
      image={bg1}
      position="center bottom"
      className="py-0 overflow-hidden light full-width"
    >
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          lg={8}
          xl={4}
          md={11}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h1 className="text-white fw-light">
            Traga{' '}
            <span className="fw-bold" style={{color:'yellow'}}>
              <Typewriter
                loop={false}
                color="yellow"
                typeSpeed={70}
                cursorStyle="|"
                deleteSpeed={50}
                delaySpeed={1000}
                cursor={!isRTL ? true : false}
                words={['AGILIDADE', 'SOFISTICAÇÃO', 'PRATICIDADE', 'DESEMPENHO']}
              />
            </span>
            <br />
            ao seu negócio
          </h1>
          <p className="lead text-white opacity-75">
            Transforme suas vendas em experiências incríveis! Venha para Request System.
            Aqui você encontra uma solução inteligente para um negócio moderno!
          </p>
        </Col>
        <Col
          xl={{ span: 7, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        >
          <div style={{bottom: "300px"}}>
            <img
              alt=""
              src={maquina}
              className="img-fluid"
              style={{width:"67%"}}
            />
          </div>
        </Col>
        <Col>
          <Button
            size="lg"
            variant="outline-light"
            onClick={scrollToContactForm}
            className="border-2 rounded-pill mt-4 fs-0 py-2 initial-page-button"
          >
            Entre em contato conosco!
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;