import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const LoginButton = () => {
  return (
    <Button 
      as={Link} 
      to="/login"
      className="login-link nav-link fw-semi-bold">
      Login
    </Button>
  );
};

export default LoginButton;