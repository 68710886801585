import React from "react";
import PdfButton from "./pdfButton";
import EditButton from "./editButton";
import DeleteModal from "./deleteModal";
import QrCodeButton from "./qrCodeButton";
import DetailButton from "./detailButton";
import DashboardButton from "./dashboardButton";
import DuplicateButton from "./duplicateButton";
import MarkAsResolvedButton from "./markAsResolvedButton";

export default function ActionsColumn({
  id,
  showPDF,
  getData,
  showEdit,
  setLoaded,
  editRoute,
  showQrCode,
  controller,
  showDelete,
  showDetail,
  deleteRoute,
  detailRoute,
  pdfFunction,
  qrCodeValue,
  showDuplicate,
  showDashboard,
  dashboardRoute,
  detailGridData,
  showMarkAsResolved,
  markAsResolvedRoute
}) {
  return (
    <div
      className='actions-column-index'
    >
      { showMarkAsResolved ? (<MarkAsResolvedButton route={markAsResolvedRoute} getData={getData} setLoaded={setLoaded} />) : (<></>)}
      { showQrCode ? (<QrCodeButton value={qrCodeValue} />) : (<></>)}
      { showPDF ? (<PdfButton func={pdfFunction}/>) : (<></>) }
      { showDashboard ? (<DashboardButton route={dashboardRoute}/>) : (<></>) }
      { showEdit ? (<EditButton route={editRoute}/>) : (<></>) }
      { showDetail ? (<DetailButton route={detailRoute} gridData={detailGridData}/>) : (<></>) }
      { showDelete ? (<DeleteModal 
                        getData={getData}
                        route={deleteRoute}
                        setLoaded={setLoaded}
                        controller={controller}
                      />) : (<></>) }
      { showDuplicate ? <DuplicateButton 
                          id={id}
                          getData={getData}
                        /> : <></> }
    </div>
  )
}