import Flex from '../Flex';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdvanceTablePagination = ({
  pageCount,
  pageIndex,
  goToPage,
  setCurrentPage
}) => {
  const maxPagesToShow = 5;
  const [currentChunk, setCurrentChunk] = useState(0);
  const [pagesChunks, setPagesChunks] = useState([[]]);

  const pagesToShow = () => {
    const arrayOfChunks = [];
    const totalChunks = Math.ceil(pageCount/maxPagesToShow);

    for(let count = 1; count <=  totalChunks; count++) {
      const chunkArray = [];

      for(let index = 1; index <= maxPagesToShow; index++) {
        if (count === 1 && pageCount >= index) chunkArray.push(index);
        else if((((count - 1) * maxPagesToShow) + index) <= pageCount && count > 1) chunkArray.push(((count - 1) * maxPagesToShow) + index);
        else break;
      }

      arrayOfChunks.push(chunkArray);
    }

    setPagesChunks(arrayOfChunks);
    setCurrentChunk(() => {
        return Math.ceil(pageIndex/maxPagesToShow) - 1
    });
  }

  useEffect(() => {
    pagesToShow();
  }, []);

  useEffect(() => {
    pagesToShow();
  }, [pageCount]);
  
  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="md"
        variant="falcon-default"
        className={classNames({ disabled: pageIndex === 1 })}
        onClick={() => {
          const pageToGo = pageIndex - 1;
          
          goToPage(pageToGo);
          setCurrentPage(pageToGo);
        }}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-1">
        {
          currentChunk > 0 ? 
            <li>
              <Button
                size="md"
                variant="falcon-default"
                style={{marginRight: '4px'}}
                onClick={() => {
                  const minusOne = currentChunk - 1;
                  
                  goToPage((minusOne * maxPagesToShow) + maxPagesToShow);
                  setCurrentPage((minusOne * maxPagesToShow) + maxPagesToShow);
                }}
              >
                ...
              </Button>
            </li> :
            <></>
        }
        {pagesChunks[currentChunk]?.map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size="md"
              variant="falcon-default"
              onClick={() => {
                goToPage(page);
                setCurrentPage(page);
              }}
              className={classNames('page', {
                'me-1': index !== pageCount
              })}
            >
              {page}
            </Button>
          </li>
        ))}
        {
          currentChunk < (pagesChunks.length - 1) && pagesChunks.length > 1 ? 
            <li>
              <Button
                size="md"
                variant="falcon-default"
                onClick={() => {
                  const plusOne = currentChunk + 1;
                  
                  goToPage((plusOne * maxPagesToShow) + 1);
                  setCurrentPage((plusOne * maxPagesToShow) + 1);
                }}
              >
                ...
              </Button>
            </li> :
            <></>
        }
      </ul>
      <Button
        size="md"
        variant="falcon-default"
        className={classNames({ disabled: pageIndex === pageCount || pageCount === 0})}
        onClick={() => {
          const pageToGo = pageIndex + 1;
          
          goToPage(pageToGo);
          setCurrentPage(pageToGo);
        }}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
    </Flex>
  );
};

export default React.memo(AdvanceTablePagination);