export default function generateRandomHexColor() {
  const red = Math.floor(Math.random() * 230);
  const green = Math.floor(Math.random() * 230);
  const blue = Math.floor(Math.random() * 230);

  const hexRed = red.toString(16).padStart(2, '0').toUpperCase();
  const hexGreen = green.toString(16).padStart(2, '0').toUpperCase();
  const hexBlue = blue.toString(16).padStart(2, '0').toUpperCase();
  
  return `#${hexRed}${hexGreen}${hexBlue}`;
}