import React from "react";
import { Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FieldValidationMessage from "./fieldValidationMessage";

export default function UploadField({
  files,
  imgUrl,
  required,
  multiple,
  fieldName,
  getRootProps,
  acceptedFiles,
  getInputProps
}) {
  return (
      <div className="form-upload-image-wrapper">
        <div {...getRootProps({ className: 'dropzone-area py-5 form-upload-image-field' })}>
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">Arraste o arquivo aqui.</p>
          </Flex>
          <Form.Control
            required={required}
            {...getInputProps({multiple:multiple})}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage fieldName={fieldName} />
          </Form.Control.Feedback>
        </div>
        {acceptedFiles.length > 0 ?
          <div className="mt-3 form-upload-image-img">
              <ul className="form-upload-image-ul">{files}</ul>
          </div> 
          : 
          <div className="mt-3 form-upload-image-img">
            <ul className="form-upload-image-ul">
              <li key={imgUrl}>
                <img src={imgUrl}  height='100' className="config-store-image" />
              </li>
            </ul>
          </div>  
        }
      </div>
  )
}