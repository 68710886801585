const estados = [
  { value: 11, label: "Rondônia" },
  { value: 12, label: "Acre" },
  { value: 13, label: "Amazonas" },
  { value: 14, label: "Roraima" },
  { value: 15, label: "Pará" },
  { value: 16, label: "Amapá" },
  { value: 17, label: "Tocantins" },
  { value: 21, label: "Maranhão" },
  { value: 22, label: "Piauí" },
  { value: 23, label: "Ceará" },
  { value: 24, label: "Rio Grande do Norte" },
  { value: 25, label: "Paraíba" },
  { value: 26, label: "Pernambuco" },
  { value: 27, label: "Alagoas" },
  { value: 28, label: "Sergipe" },
  { value: 29, label: "Bahia" },
  { value: 31, label: "Minas Gerais" },
  { value: 32, label: "Espírito Santo" },
  { value: 33, label: "Rio de Janeiro" },
  { value: 35, label: "São Paulo" },
  { value: 41, label: "Paraná" },
  { value: 42, label: "Santa Catarina" },
  { value: 43, label: "Rio Grande do Sul" },
  { value: 50, label: "Mato Grosso do Sul" },
  { value: 51, label: "Mato Grosso" },
  { value: 52, label: "Goiás" },
  { value: 53, label: "Distrito Federal" }
];

export default estados;