import React from "react";
import { Button } from "react-bootstrap";
import PdfFile from "../../../assets/img/icons/request-system/pdf-file.svg";


export default function PdfButton({ func }) {
  return (
    <Button 
      title='PDF'
      className={'pdf-button-index'}
      onClick={func}
    >
      <img 
        src={PdfFile}
        alt='Baixar cardápio'
        className='button-icon'
      />
    </Button>
  )
}