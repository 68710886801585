const windowHeight = window.innerHeight;

export default () => {
  const scrollTop = window.scrollY;

  let alpha = (scrollTop / windowHeight) * 2;
  alpha >= 1 && (alpha = 1);

  const element = document.getElementsByClassName('navbar-theme');

  if(element && element.length > 0 && element[0].style)
   element[0].style.backgroundColor = `rgba(37, 37, 37, ${alpha})`;
};
