import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

export default function CreateSellPointModal({modalShow, setModalShow, handleCreateSellPoints}) {
  const [data, setData] = useState(null);

  return (
    <Modal
      centered
      size="md"
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title 
          className="delete-modal-title"
          id="contained-modal-title-vcenter"
        >
          Criar Pontos de Venda
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Quantidade</Form.Label>
            <Form.Control
              type="number"
              onChange={(event) => {
                setData(event.target.value);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          title="Cancelar"
          variant="secundary"
          onClick={() => setModalShow(false)}
        >
          Cancelar
        </Button>
        <Button 
          title="Criar"
          variant="primary"
          onClick={(e) => handleCreateSellPoints(e, data)}
        >
          Criar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}