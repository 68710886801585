export const version = '1.0.0';
export const navbarBreakPoint = 'xl';
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isRTL: false,
  isDark: false,
  isFluid: true,
  currency: 'R$',
  showBurgerMenu: false,
  navbarStyle: 'inverted',
  navbarPosition: 'vertical',
  isNavbarVerticalCollapsed: false
};

export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };