import use from 'assets/img/icons/request-system/use.svg'
import payments from 'assets/img/icons/request-system/payments.svg'
import dashboardHome from 'assets/img/icons/request-system/dashboard-home.svg'

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'CRIE',
    color: 'success',
    title: 'Utilização',
    description:
      'Com a Request System, é possível a criação de cardápios personalizados para seu restaurante, em poucos minutos, seus itens já estarão no cardápio do aplicativo, prontos para serem vendidos',
    image: use
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'ADMINISTRE',
    color: 'info',
    title: 'Dashboard e informativos',
    description:
      "Tenha controle total dos seus dados, acompanhe suas vendas em tempo real com gráficos precisos e claros.",
    image: dashboardHome,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'PAGAMENTOS',
    color: 'success',
    title: 'Cartões e Máquinas',
    description:
      'Utilize diferentes formas de pagamento, de maneira simples e eficaz, nosso software conta com emissão de tickets, emissão de nota fiscal entre outras inúmeras funcionalidades.',
    image: payments
  }
];
