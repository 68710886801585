import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import InsertIcon from "assets/img/icons/request-system/insert.svg";

export default function InsertButton({ route }) {
  const navigate = useNavigate();

  return (
    <Button
      title='Incluir'
      className='add-button-index'
      onClick={() => { navigate(route) }}
    >
      <img 
        alt='Incluir'
        src={InsertIcon}
        className='button-icon'
      />
    </Button>
  )
}