import useAuth from 'hooks/useAuth';
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import FullScreenLoading from '../sistema/fullScreenLoading';
import { formaPagamentoRoles } from '../sistema/allowedRoles';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import FieldValidationMessage from '../sistema/fieldValidationMessage';

import { useDropzone } from 'react-dropzone';
import UploadField from '../sistema/formUploadField';

export default function FormaPagamentoEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [data, setData] = useState({
    id: "",
    nome: "",
    tipo: "",
    padrao: false,
    integravel: false,
  });

  const [files, setFiles] = useState((<></>));
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*'
  });

  useEffect(() => {
    const newData = { ...data };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function() {
        const filteredBase64 = reader.result.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        newData['fileName'] = file.name;
        newData['formFile'] = filteredBase64;

        setData(newData);

        setFiles((
          <li key={file.path}>
            <img src={reader.result}  height='100' />
          </li>));
      };
      
      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  const getData = async () => {
    setLoading(true);

    baseController.get(`FormaPagamento/${id}`, {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    }).then((res) => setData(res.data))
    .finally(() => setLoading(false));
  };

  useEffect(() => {
    const isAuthorized = formaPagamentoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(id && isAuthorized) getData();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const form = event.currentTarget;

    if(!form.checkValidity()) {
      event.stopPropagation();

      setValidated(true);
      setLoading(false);

      return;
    }

    id ? 
      baseController.put('FormaPagamento', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) : 
      baseController.post('FormaPagamento', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }
  
  const handleData = (event) => {
    const newData = {...data};

    switch(event.target.type) {
      case 'checkbox':
          newData[event.target.name] = event.target.checked;
          break;
      default:
          newData[event.target.name] = event.target.value;
          break;
    }
    
    setData(newData);
  }

  if(loading) return (<FullScreenLoading />)

  if(!authorized) return (<Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar forma de pagamento' : 'Incluir forma de pagamento'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        id='forma-pagamento-form'
        className='edit-card-body'
      >
        <Form.Group className="mb-3" controlId="formGroupNome">
          <Form.Label>Nome</Form.Label>
          <Form.Control
            required
            type="text"
            minLength={2}
            maxLength={100}
            name="nome"
            value={data.nome}
            onChange={(e) => handleData(e)}
            placeholder="Nome da forma de pagamento"
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Nome"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupIntregado">
          <Form.Label>Pagamento Integrado?</Form.Label>
          <Form.Check
            id='integravel'
            name='integravel'
            type='switch'
            checked={data.integravel}
            onChange={handleData}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              fieldName={"Pagamento Integrado?"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPadrao">
          <Form.Label>Padrão?</Form.Label>
          <Form.Check 
              id='padrao'
              name='padrao'
              type='switch'
              checked={data.padrao}
              onChange={handleData}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              fieldName={"Padrão?"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        { data.integravel ? 
          <Form.Group className="mb-2" controlId="formGroupTipo">
            <Form.Label>Tipo de Pagamento</Form.Label>
            <Row>
              <Col>
                <Form.Check type="radio" className="mb-0 form-check" id="credito">
                  <Form.Check.Input 
                    type="radio"
                    id="credito"
                    name="tipo"
                    value="credito"
                    checked={data.tipo === "credito"}
                    onChange={(e) => handleData(e)}
                  />
                  <Form.Check.Label>Cartão de Crédito</Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="radio" className="mb-0 form-check" id="debito">
                  <Form.Check.Input 
                    type="radio"
                    id="debito"
                    name="tipo"
                    value="debito"
                    checked={data.tipo === "debito"}
                    onChange={(e) => handleData(e)}
                  />
                  <Form.Check.Label>Cartão de Débito</Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="radio" className="mb-0 form-check" id="voucher">
                  <Form.Check.Input 
                    type="radio"
                    id="voucher"
                    name="tipo"
                    value="voucher"
                    checked={data.tipo === "voucher"}
                    onChange={(e) => handleData(e)}
                  />
                  <Form.Check.Label>VR/VA</Form.Check.Label>
                </Form.Check>
              </Col>
              <Col>
                <Form.Check type="radio" className="mb-0 form-check" id="pix">
                  <Form.Check.Input 
                    id="pix"
                    name="tipo"
                    value="pix"
                    type="radio"
                    checked={data.tipo === "pix"}
                    onChange={(e) => handleData(e)}
                  />
                  <Form.Check.Label>Pix</Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          </Form.Group> : <></>
        }
        <Form.Group 
          className="mb-3" 
          controlId="formGroupImagem"
        >
          <Form.Label>Imagem</Form.Label>
          <UploadField
            files={files}
            required={!id && !data.formFile}
            multiple={false}
            fieldName="Imagem"
            imgUrl={data.imagem}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            acceptedFiles={acceptedFiles}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer className="edit-buttons-wrapper">
        <Button 
          type="button"
          variant="danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="primary"
          form="forma-pagamento-form"
          className="edit-save-button"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}