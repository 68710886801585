import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import { useDropzone } from "react-dropzone";
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import UploadField from '../sistema/formUploadField';
import { Card, Form, Button } from 'react-bootstrap';
import { usuarioRoles } from '../sistema/allowedRoles';
import PasswordButton from '../sistema/passwordButton';
import { useNavigate, useParams } from "react-router-dom";
import FieldValidationMessage from '../sistema/fieldValidationMessage';
import FullScreenLoading from '../sistema/fullScreenLoading';

export default function UsuarioEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [files, setFiles] = useState((<></>));
  const [loading, setLoading] = useState(true);
  const [validated, setValidated] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  const [valuePessoa, setValuePessoa] = useState(null);
  const [valueFuncao, setValueFuncao] = useState(null);

  const [comboValuesPessoa, setComboValuesPessoa] = useState([]);
  const [comboValuesFuncao, setComboValuesFuncao] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*'
  });

  useEffect(() => {
    const newData = { ...data };
    const reader = new FileReader();

    acceptedFiles.map(file => {
      reader.onloadend = function() {
        const filteredBase64 = reader.result.replace(/(data[a-zA-Z0-9:/;].+?base64,)/g, "");

        newData['fileName'] = file.name;
        newData['formFile'] = filteredBase64;

        setData(newData);

        setFiles((
          <li key={file.path}>
            <img src={reader.result}  height='100' />
          </li>
        ))
      };

      reader.readAsDataURL(file);
    });
  }, [acceptedFiles]);

  const [data, setData] = useState({
    nome: "",
    senha: "",
    imagem: "",
    fileName: null,
    formFile: null,
    pessoaId: "",
    funcaoId: ""
  });

  const getData = async () => {
    setLoading(true);

    if (id) {
      const responseUsuario = await baseController.get(`Usuario/${id}`, { 
        headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        } 
      });
      const editAdjustedToComboPessoa = adjustDataSingleToComboPessoa(responseUsuario.data.pessoa);
      const editAdjustedToComboFuncao = adjustDataSingleToComboFuncao(responseUsuario.data.funcao);

      setData(responseUsuario.data);
      setValuePessoa(editAdjustedToComboPessoa);
      setValueFuncao(editAdjustedToComboFuncao);
    }

    const responsePessoa = await baseController.get('Pessoa/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });
    
    const rolesToFilter = ['CM-A', 'CM-C', 'CM-V'],
          role = auth.role ?? JSON.parse(localStorage.getItem('auth')).role;

    setComboValuesPessoa(adjustDataToComboPessoa(responsePessoa.data));

    const responseFuncao = await baseController.get('Funcao/GetAll', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    });

    let data;

    if(rolesToFilter.includes(role))
      data = responseFuncao.data.filter(p => rolesToFilter.includes(p.tipoAcesso));
    
    setComboValuesFuncao(adjustDataToComboFuncao(data ?? responseFuncao.data));
  };

  const adjustDataSingleToComboPessoa = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };

  const adjustDataToComboPessoa = (data) => {
    return data.map((pessoa) => {
      return {
        value: pessoa.id,
        nome: pessoa.nome,
        label: pessoa.nome
      }
    })
  };

  const adjustDataSingleToComboFuncao = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };

  const adjustDataToComboFuncao = (data) => {
    return data.map((funcao) => {
      return {
        value: funcao.id,
        nome: funcao.nome,
        label: funcao.nome
      }
    })
  };

  useEffect(() => {
    const pessoaId = valuePessoa?.value;
    const funcaoId = valueFuncao?.value;
    const newData = {...data};
    
    newData['pessoaId'] = pessoaId;
    newData['pessoa'] = null;
    newData['funcaoId'] = funcaoId;
    newData['funcao'] = null;

    setData(newData);
  }, [valuePessoa, valueFuncao]);
  
  useEffect(() => {
    const isAuthorized = usuarioRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized) getData();

    setLoading(false);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (!form.checkValidity()) {
      event.stopPropagation();
      setValidated(true);

      return null;
    }

    id ?
      await baseController.put('Usuario', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      }) : 
      await baseController.post('Usuario', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      });

    navigate(-1);
  }
  
  const handleData = (event) => {
    const newData = {...data};

    newData[event.target.name] = event.target.value;
    setData(newData);
  }

  if(loading) return (<FullScreenLoading />);
  
  if(!authorized) return (<Error401 />);

  return (
    <Card className='edit-card'>
      <Card.Header>
        <h1 className='edit-form-title'>
          { id ? 'Editar usuário' : 'Incluir usuário'}
        </h1>
      </Card.Header>
      <Card.Body
        as={Form}
        noValidate
        id='usuario-form'
        validated={validated}
        onSubmit={handleSubmit}
        className='edit-card-body'
      >
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Usuário</Form.Label>
          <Form.Control
            required
            name="nome"
            type="text"
            minLength={2}
            maxLength={100}
            value={data.nome}
            autoComplete="no"
            placeholder="Nome do usuário"
            onChange={(e) => handleData(e)}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage
              minLength={2}
              maxLength={100}
              fieldName={"Usuário"}
            />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword">
          <Form.Label>Senha</Form.Label>
          <div className="input-with-button-wrapper">
            <Form.Control
              className="input-with-button"
              required
              name="senha"
              value = {data.senha}
              type="password"
              placeholder="Senha do usuário"
              onChange={(e) => handleData(e)}
              minLength={5}
              maxLength={255}
              autoComplete="no"
            />
            <PasswordButton
              data={data}
              setData={setData}
            />
            <Form.Control.Feedback type="invalid">
              <FieldValidationMessage
                minLength={5}
                maxLength={255}
                fieldName={"Senha"}
              />
            </Form.Control.Feedback>
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPerson">
          <Form.Label>Pessoa</Form.Label>
          <Select
            name='pessoaId'
            value={valuePessoa}
            closeMenuOnSelect={true}
            options={comboValuesPessoa}
            classNamePrefix='react-select'
            placeholder='Selecione a pessoa'
            onChange={(valuePessoa) => { setValuePessoa(valuePessoa) }}
            className={validated && valuePessoa === null ? 'form-control is-invalid' : ''}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage fieldName={"Pessoa"} />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupFunction">
          <Form.Label>Função</Form.Label>
          <Select
            name='funcaoId'
            value={valueFuncao}
            closeMenuOnSelect={true}
            options={comboValuesFuncao}
            classNamePrefix='react-select'
            placeholder='Selecione a função'
            onChange={(valueFuncao) => { setValueFuncao(valueFuncao) }}
            className={validated && valueFuncao === null ? 'form-control is-invalid' : ''}
          />
          <Form.Control.Feedback type="invalid">
            <FieldValidationMessage fieldName={"Função"} />
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group 
          className="mb-3" 
          controlId="formGroupImagem"
        >
          <Form.Label>Imagem</Form.Label>
          <UploadField
            files={files}
            multiple={false}
            fieldName="Imagem"
            imgUrl={data.imagem}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            acceptedFiles={acceptedFiles}
          />
        </Form.Group>
      </Card.Body>
      <Card.Footer className='edit-buttons-wrapper'>
        <Button 
          variant="danger"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancelar
        </Button>
        <Button 
          type="submit"
          variant="primary"
          form="usuario-form"
        >
          Salvar
        </Button>
      </Card.Footer>
    </Card>
  )
}