import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Potencialize suas Vendas com Request System"
      subtitle="Gerencie vendas e clientes de forma eficiente com relatórios em tempo real para tomar decisões inteligentes."
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process key={index} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;