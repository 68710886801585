import React from 'react';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import FalconComponentCard from 'components/common/FalconComponentCard';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';

echarts.use([
  LineChart,
  GridComponent,
  TitleComponent,
  CanvasRenderer,
  TooltipComponent,
]);

const tooltipFormatter = params => {
  return `
  <div>
      <h6 class="fs--1 text-700 mb-0">
      <span class="dot me-1 d-inline-block" style="background-color:${
        params[0].borderColor ? params[0].borderColor : params[0].color
      }"></span>
        ${params[0].name} : ${params[0].value}
      </h6>
  </div>`;
};

const ChartCode = ({listData}) => {

  const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  
  const data = hours.map((hora) => {
    return listData.find(item => item.hora === hora)?.valor ?? 0
  });

  const getOption = () => ({
    tooltip: {
      borderWidth: 1,
      trigger: 'axis',
      padding: [7, 10],
      transitionDuration: 1,
      textStyle: getColor('dark'),
      formatter: tooltipFormatter,
      axisPointer: { type: 'none' },
      borderColor: getColor('gray-300'),
      backgroundColor: getColor('gray-100')
    },
    xAxis: {
      data: hours,
      type: 'category',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#000'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: '#000',
        formatter: value => value,
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-300')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: '#000',
        margin: 15
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0
    },
    series: [
      {
        type: 'line',
        data,
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('primary'),
          borderWidth: 4
        },
        lineStyle: {
          color: getColor('primary')
        },
        showSymbol: false,
        symbol: 'circle',
        symbolSize: 10,
        smooth: true,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '18.75rem' }}
    />
  );
}

const VendaPorHoraEvento = ({data}) => {
  return (
    <FalconComponentCard className='dashboard-card-shadow'>
      <FalconComponentCard.Body>
        <h4 className="mt-1">Vendas por Hora</h4>
        <ChartCode listData={data}/>
      </FalconComponentCard.Body>
    </FalconComponentCard>
  );
};

export default VendaPorHoraEvento;