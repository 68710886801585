import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import FilterIcon from 'assets/img/icons/request-system/filter.svg';

export default function FilterButton({ getData, FilterFields, specificFilter, setSpecificFilter, clearFilterValues }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button 
        title='Filtros'
        onClick={handleShow}
        className='filter-button-index'
      >
        <img 
          alt='Filtros'
          src={FilterIcon}
          className='button-icon'
        />
      </Button>

      <Offcanvas 
        show={show}
        placement='end'
        onHide={handleClose}
      >
        <Offcanvas.Header 
          closeButton
          className='filter-bar-header'
        >
          <Offcanvas.Title className='filter-bar-title'>Filtros</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='filter-bar-body'>
          <FilterFields
            specificFilter={specificFilter}
            setSpecificFilter={setSpecificFilter}
          />
        </Offcanvas.Body>
        <div className='filter-bar-footer'>
          <Button
            variant='danger'
            title='Cancelar'
            onClick={() => {
              handleClose();
              clearFilterValues();
            }}
            className='filter-bar-footer-button'
          >
            Cancelar
          </Button>
          <Button
            title='Aplicar'
            variant='primary'
            className='filter-bar-footer-button'
            onClick={() => {
              getData();
              handleClose();
            }}
          >
            Aplicar
          </Button>
        </div>
      </Offcanvas>
    </>
  );
}