import is from 'is_js';
import MainLayout from './MainLayout';
import AppContext from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { CloseButton } from 'components/common/Toast';
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from 'components/pages/sistema/requireAuth';

import {
  homeRoles,
  lojaRoles,
  funcaoRoles,
  pessoaRoles,
  eventoRoles,
  produtoRoles,
  usuarioRoles,
  cardapioRoles,
  categoriaRoles,
  notificacaoRoles,
  dispositivoRoles,
  lojaDashboradRole,
  formaPagamentoRoles,
  contatoRecebidoRoles
} from "components/pages/sistema/allowedRoles";

import Home from '../components/pages/home/index';

import Funcao from '../components/pages/funcao/index';
import FuncaoEdit from '../components/pages/funcao/edit';

import Dispositivo from '../components/pages/dispositivo/index';
import DispositivoEdit from '../components/pages/dispositivo/edit';

import Pessoa from '../components/pages/pessoa/index';
import PessoaEdit from '../components/pages/pessoa/edit';

import Usuario from '../components/pages/usuario/index';
import UsuarioEdit from '../components/pages/usuario/edit';

import Evento from '../components/pages/evento/index';
import EventoEdit from '../components/pages/evento/edit';
import EventoDetail from '../components/pages/evento/detail';
import EventoDashboard from '../components/pages/evento/dashboard';

import ContatoRecebido from 'components/pages/contatoRecebido';
import ContatoRecebidoDetail from 'components/pages/contatoRecebido/detail';

import Cardapio from '../components/pages/cardapio/index';
import CardapioEdit from '../components/pages/cardapio/edit';
import CardapioDetail from '../components/pages/cardapio/detail';

import Notificacao from '../components/pages/notificacao/index';
import NotificacaoEdit from '../components/pages/notificacao/edit';

import FormaPagamento from '../components/pages/formaPagamento/index';
import FormaPagamentoEdit from '../components/pages/formaPagamento/edit';

import Categoria from '../components/pages/categorias/index';
import CategoriaEdit from '../components/pages/categorias/edit';

import Produto from '../components/pages/produtos/index';
import ProdutoEdit from '../components/pages/produtos/edit';

import Loja from '../components/pages/loja/index';
import LojaEdit from '../components/pages/loja/edit';

import Login from '../components/pages/login/Login';

import PaginaInicial from '../components/pages/paginaInicial/index';
import ClienteDashboard from 'components/pages/dashboards/cliente';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route path="/" element={<PaginaInicial/>} />
        <Route path="/login" element={<Login/>} />
        
        <Route element={<MainLayout />}>
          <Route element={<RequireAuth allowedRoles={homeRoles}/>}>
            <Route path="/home" element={<Home />} />
          </Route>
          
          <Route element={<RequireAuth allowedRoles={lojaRoles}/>}>
            <Route path="loja" element={<Loja />} />
            <Route path="loja/edit" element={<LojaEdit />} />
            <Route path="loja/edit/:id" element={<LojaEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={funcaoRoles}/>}>
            <Route path="funcao" element={<Funcao />} />
            <Route path="funcao/edit" element={<FuncaoEdit />} />
            <Route path="funcao/edit/:id" element={<FuncaoEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={dispositivoRoles}/>}>
            <Route path="dispositivo" element={<Dispositivo />} />
            <Route path="dispositivo/edit" element={<DispositivoEdit />} />
            <Route path="dispositivo/edit/:id" element={<DispositivoEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={pessoaRoles}/>}>
            <Route path="pessoa" element={<Pessoa />} />
            <Route path="pessoa/edit" element={<PessoaEdit />} />
            <Route path="pessoa/edit/:id" element={<PessoaEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={usuarioRoles}/>}>
            <Route path="usuario" element={<Usuario />} />
            <Route path="usuario/edit" element={<UsuarioEdit />} />
            <Route path="usuario/edit/:id" element={<UsuarioEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={eventoRoles}/>}>
            <Route path="evento" element={<Evento />} />
            <Route path="evento/edit" element={<EventoEdit />} />
            <Route path="evento/edit/:id" element={<EventoEdit />} />
            <Route path="evento/detail/:id" element={<EventoDetail />} />
            <Route path="evento/dashboard/:id" element={<EventoDashboard />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={lojaDashboradRole}/>}>
            <Route path="evento/dashboard/:idEvento/:idLoja" element={<ClienteDashboard />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={cardapioRoles}/>}>
            <Route path="cardapio" element={<Cardapio />} />
            <Route path="cardapio/edit" element={<CardapioEdit />} />
            <Route path="cardapio/edit/:id" element={<CardapioEdit />} />
            <Route path="cardapio/detail/:id" element={<CardapioDetail />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={categoriaRoles}/>}>
            <Route path="categoria" element={<Categoria />} />
            <Route path="categoria/edit" element={<CategoriaEdit />} />
            <Route path="categoria/edit/:id" element={<CategoriaEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={produtoRoles}/>}>
            <Route path="produto" element={<Produto />} />
            <Route path="produto/edit" element={<ProdutoEdit />} />
            <Route path="produto/edit/:id" element={<ProdutoEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={notificacaoRoles}/>}>
            <Route path="notificacao" element={<Notificacao />} />
            <Route path="notificacao/edit" element={<NotificacaoEdit />} />
            <Route path="notificacao/edit/:id" element={<NotificacaoEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={formaPagamentoRoles}/>}>
            <Route path="formaPagamento" element={<FormaPagamento />} />
            <Route path="formaPagamento/edit" element={<FormaPagamentoEdit />} />
            <Route path="formaPagamento/edit/:id" element={<FormaPagamentoEdit />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={contatoRecebidoRoles}/>}>
            <Route path="contatoRecebido" element={<ContatoRecebido />} />
            <Route path="contatoRecebido/detail/:id" element={<ContatoRecebidoDetail />} />
          </Route>
        </Route>

        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;