import {
  homeRoles,
  lojaRoles,
  funcaoRoles,
  pessoaRoles,
  eventoRoles,
  produtoRoles,
  usuarioRoles,
  cardapioRoles,
  categoriaRoles,
  notificacaoRoles,
  dispositivoRoles,
  mainCardapioRoles,
  formaPagamentoRoles,
  contatoRecebidoRoles,
} from "components/pages/sistema/allowedRoles";

export const pagesRoutes = {
  label: 'Cadastros',
  children: [
    {
      name: 'Início',
      to: '/home',
      icon: 'home',
      active: true,
      roles: homeRoles
    },
    {
      name: 'Cardápios',
      icon: 'hamburger',
      active: true,
      roles: mainCardapioRoles,
      children: [
        {
          name: 'Cardápios',
          to: '/cardapio',
          exact: true,
          roles: cardapioRoles,
          active: true
        },
        {
          name: 'Categorias',
          to: '/categoria',
          exact: true,
          roles: categoriaRoles,
          active: true
        },
        {
          name: 'Produtos',
          to: '/produto',
          exact: true,
          roles: produtoRoles,
          active: true
        }
      ]
    },
    {
      name: 'Contatos Recebidos',
      icon: 'envelope',
      to: '/contatoRecebido',
      roles: contatoRecebidoRoles,
      active: true
    },
    {
      name: 'Dispositivos',
      icon: 'mobile-alt',
      to: '/dispositivo',
      active: true,
      roles: dispositivoRoles,
    },
    {
      name: 'Eventos',
      icon: 'calendar-day',
      to: '/evento',
      roles: eventoRoles,
      active: true
    },
    {
      name: 'Formas de Pagamento',
      icon: 'dollar-sign',
      to: '/formaPagamento',
      roles: formaPagamentoRoles,
      active: true
    },
    {
      name: 'Funções',
      icon: 'info-circle',
      to: '/funcao',
      roles: funcaoRoles,
      active: true
    },
    {
      name: 'Lojas',
      icon: 'store',
      to: '/loja',
      roles: lojaRoles,
      active: true
    },
    {
      name: 'Notificações',
      icon: 'bell',
      to: '/notificacao',
      roles: notificacaoRoles,
      active: true
    },
    {
      name: 'Pessoas',
      icon: 'people-arrows',
      to: '/pessoa',
      roles: pessoaRoles,
      active: true
    },
    {
      name: 'Usuários',
      icon: 'users',
      to: '/usuario',
      roles: usuarioRoles,
      active: true
    }
  ]
};

export default [pagesRoutes];