import React from 'react';
import { Card } from 'react-bootstrap';

const Error401 = () => {
  return (
    <Card className="grid-card text-center" >
      <Card.Body className="p-5 error-card">
        <div className="display-1 text-300 fs-error">401</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          Você não está autorizado a acessar essa página.
        </p>
        <hr />
      </Card.Body>
    </Card>
  );
};

export default Error401;