import axios from 'axios';

export const baseController = 
  axios.create({
    timeout: 20000,
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'x-functions-key': process.env.REACT_APP_API_KEY
    }
  });

export default baseController;