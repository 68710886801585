import Select from 'react-select';
import useAuth from 'hooks/useAuth';
import DatePicker from "react-datepicker";
import baseController from 'api/base.controller';
import Error401 from 'components/pages/errors/Error401';
import React, { useState, useEffect } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { notificacaoRoles } from '../sistema/allowedRoles';
import FullScreenLoading from '../sistema/fullScreenLoading';

export default function NotificacaoEdit() {
  const { id } = useParams();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [valueUsuario, setValueUsuario] = useState(null);
  const [comboValuesUsuario, setComboValuesUsuario] = useState([]);
  const [data, setData] = useState({
    texto: "",
    link: "",
    data: "",//Ver com o bruno p/ preencher com o dia atual // Se formos adicionar no sistema, fazer validações com os campos notnull
    destinatarioId: "",
    lida: true
  });

  const getData = async () => {
    setLoading(true);

    if (id) {
      baseController.get(`Notificacao/${id}`, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then((res) => {
          setData(res.data);
          setValueUsuario(adjustDataSingleToComboUsuario(res.data.destinatario));
        });
    }

    baseController.get('Usuario', {
      headers: {
        'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
      }
    })
      .then((res) => setComboValuesUsuario(adjustDataToComboUsuario(res.data)))
      .finally(() => setLoading(false));
  };

  const adjustDataSingleToComboUsuario = (data) => {
    return {
      value: data.id,
      nome: data.nome,
      label: data.nome
    };
  };

  const adjustDataToComboUsuario = (data) => {
    return data.map((usuario) => {
      return {
        value: usuario.id,
        usuario: usuario.nome,
        label: usuario.nome
      }
    })
  };

  useEffect(() => {
    const destinatarioId = valueUsuario?.value;
    const newData = {...data};
    
    newData['destinatarioId'] = destinatarioId;
    setData(newData);

    if(id) getData();
  }, [valueUsuario]);

  useEffect(() => {
    const isAuthorized = notificacaoRoles.includes(auth.role ?? JSON.parse(localStorage.getItem('auth')).role);

    setAuthorized(isAuthorized);

    if(isAuthorized && id) getData();
  }, []);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    
    id ? 
      baseController.put('Notificacao', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false)) : 
      baseController.post('Notificacao', data, {
        headers: {
          'Authorization': auth.token ?? JSON.parse(localStorage.getItem('auth')).token
        }
      })
        .then(() => navigate(-1))
        .finally(() => setLoading(false));
  }
  
  const handleData = (event) => {
    const newData = {...data};

    newData[event.target.name] = event.target.value;

    setData(newData);
  }

  if(loading) return (<FullScreenLoading />);

  if(!authorized) return ( <Error401 />);

  return(
  <Card>
    <Card.Header>
      <h1 className='edit-form-title'>
        { id ? 'Editar notificação' : 'Incluir notificação'}
      </h1>
    </Card.Header>
    <Card.Body className='edit-card-body'>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Mensagem</Form.Label>
          <Form.Control
            name="texto"
            value={data.texto}
            type="text"
            placeholder="Texto da notificação"
            onChange={(e) => handleData(e)}
          />
          <Form.Label>Link</Form.Label>
          <Form.Control
            name="link"
            value={data.link}
            type="text"
            placeholder="Link da notificação"
            onChange={(e) => handleData(e)}
          />
          <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Usuario</Form.Label>
            <Select
              name="destinatarioId"
              closeMenuOnSelect={true}
              options={comboValuesUsuario}
              placeholder='Selecione o destinatário'
              classNamePrefix="react-select"
              value={valueUsuario}
              onChange={valueUsuario => setValueUsuario(valueUsuario)}
            />
            <Form.Label>Data Notificacao</Form.Label>
            <DatePicker
              name = "data"
              selected={date}
              onChange={(date)=>setDate(date)}
              className='form-control'
              placeholderText="DD-MM-YYYY h:mm aa"
              timeIntervals={5}
              dateFormat="dd-MM-yyyy h:mm aa"
              showTimeSelect
              fixedHeight
            />
            <br></br>
            <Form.Label>Lida</Form.Label>
            <Form.Check 
              name="lida"
              value={data.lida}
              type='checkbox'
              id='disabledCheckbox'
              label='Lida'
              disabled
            />
        </Form.Group>
        </Form.Group>
        <Button 
          variant="danger" 
          type="submit" 
          style={{marginRight: '10px'}}
          onClick={() => {
            navigate('/notificacao');
          }}
        >
          Cancelar
        </Button>
        <Button 
          variant="primary" 
          type="submit"
        >
          Salvar
        </Button>
      </Form>
    </Card.Body>
  </Card>
  )
}