const steps = [
  {
    icon: 'calendar-day',
    label: 'Evento'
  },
  {
    icon: 'user',
    label: 'Participantes'
  },
  // {
  //   icon: 'dollar-sign',
  //   label: 'Formas de Pagamento'
  // },
  // {
  //   icon: 'cash-register',
  //   label: 'Pontos de Venda'
  // },
  {
    icon: 'store',
    label: 'Lojas'
  }
];

export default steps;