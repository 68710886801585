import App from './App';
import 'helpers/initFA';
import React from 'react';
import Main from './Main';
import ReactDOM from 'react-dom';

ReactDOM.render(
  <React.StrictMode>
      <Main>
        <App />
      </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
